import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 4,
    fontSize: 13,
  },
  imgs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  apple: {
    height: 43,
    width: 128,
    top: 0,
    left: 0,
    marginRight: 8,
  },
  android: {
    height: 43,
    width: 144,
    top: 0,
    left: 0,
  },
});
