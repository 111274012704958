import {StatusBar} from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';

const safeAreaMargin = (StatusBar && StatusBar.currentHeight) || 0;

const style = StyleSheet.create({
  safeAreaView: {
    paddingTop: safeAreaMargin,
    zIndex: '$elevationLayerS',
    width: '100%',
  },
  children: {
    flex: 1,
  },
});

export {style};
