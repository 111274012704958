import React, {forwardRef} from 'react';
import {ScrollView as RNScrollView, ScrollViewProps} from 'react-native';
import {ScrollView as RNGHScrollView} from 'react-native-gesture-handler';

import {IOSInAppBrowserVideoAdScrollView} from './iOSInAppBrowserVideoAdScrollView/IOSInAppBrowserVideoAdScrollView';
import {style} from './ScrollView.style';

import {iOSInAppBrowserVideoAd} from 'ui/helpers';

// eslint-disable-next-line react/display-name
const ScrollView = forwardRef<RNScrollView, ScrollViewProps>(({children, scrollEnabled, onScroll, ...props}, ref) => {
  if (iOSInAppBrowserVideoAd) {
    return (
      <IOSInAppBrowserVideoAdScrollView ref={ref} onScroll={onScroll} scrollEnabled={scrollEnabled}>
        {children}
      </IOSInAppBrowserVideoAdScrollView>
    );
  }

  return (
    <RNGHScrollView
      contentContainerStyle={style.scrollView}
      onScroll={onScroll}
      showsVerticalScrollIndicator={false}
      scrollEventThrottle={16}
      ref={ref}
      scrollEnabled={scrollEnabled}
      {...props}
    >
      {children}
    </RNGHScrollView>
  );
});

export {ScrollView};
