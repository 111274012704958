import React, {useEffect} from 'react';
import {useDevice} from '@lookiero/aurora';
import {useModal, MODAL_STATUS} from '@components/_context/ModalManager';
import {Platform} from '@domain/model/App/Platform';
import {delay} from '@helpers';

import {
  askForAdvertiserTracking,
  askForNotificationPermission,
  checkForNotificationPermission,
} from './RequestNotificationView.controller';
import {setAnalyticsCollectionEnabled} from './_helpers/analyticsCollection';

import {RequestNotification as RequestNotificationView} from '../../components/templates/RequestNotification';
import {setPerformanceCollectionEnabled} from '@app/components/Monitoring/_helpers/performanceCollection';

export const RequestNotification: React.FC = () => {
  const {platform}: {platform: Platform} = useDevice();

  const {isOpen, openModal, closeModal} = useModal('request-notification', MODAL_STATUS.LOADING);

  useEffect(() => {
    (async () => {
      if (platform.android) {
        setPerformanceCollectionEnabled(true);
        await setAnalyticsCollectionEnabled(true);
      }
    })();
  }, [platform]);

  useEffect(() => {
    (async () => {
      const hasPermission = await checkForNotificationPermission(platform);
      if (hasPermission) {
        await askForAdvertiserTracking(platform);
        closeModal();
      } else {
        openModal();
      }
    })();
  }, [closeModal, openModal, platform]);

  const requestPermissions = async (): Promise<void> => {
    await askForNotificationPermission(platform);
    // The delay is to prevent tracking notifications not showing in iOS 15
    // https://developer.apple.com/forums/thread/690607
    if (platform.ios) await delay(1000);
    await askForAdvertiserTracking(platform);
    closeModal();
  };

  return <RequestNotificationView isVisible={isOpen} onClose={requestPermissions} onConfirm={requestPermissions} />;
};
