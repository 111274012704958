import React, {FC, useEffect, useLayoutEffect} from 'react';
import {Platform, StatusBar} from 'react-native';
import {PaymentsFrontLoader} from '@lookiero/payments-front';
import {Theme} from '@lookiero/aurora';
import Braze from '@braze/react-native-sdk';
import eventEmitter from 'tiny-emitter/instance';
import SplashScreen from 'react-native-splash-screen';

import {useApp} from '.';

import {ModalNoInternet, NO_INTERNET_VIEW_LOADED, useInternetStatus} from './components/NoInternet';
import {ModalUpdateVersion} from '@components/atoms';
import {RequestNotification} from '@views/RequestNotification/RequestNotificationView';
import {UNAUTHORIZED_EVENT} from '@infra/api/graphQL/event';
import {useAdjust} from '@tracking/adjust/useAdjust';
import {useOverallInfo} from '@components/_context';
import {useRefreshSession} from '@app/helpers/useRefreshSession';

const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

const AppBootstrap: FC = () => {
  const {authorization, setAuthorization, loading} = useApp();
  const {id} = useOverallInfo();
  const internetStatus = useInternetStatus({loading});
  const theme = Theme.get();

  useRefreshSession();

  useAdjust();

  useEffect(() => {
    if (isNative && id) {
      Braze.changeUser(id);
    }
  }, [id]);

  useEffect(() => {
    const handleUnauthorized = (): void => setAuthorization(false);
    eventEmitter.on(UNAUTHORIZED_EVENT, handleUnauthorized);

    return () => eventEmitter.off(UNAUTHORIZED_EVENT, handleUnauthorized);
  }, [setAuthorization]);

  useLayoutEffect(() => {
    if (isNative) {
      SplashScreen.hide();
    }
  }, []);

  return (
    <>
      {isNative && <StatusBar translucent backgroundColor={theme.colorBase} barStyle={'dark-content'} />}
      {isNative && authorization && <RequestNotification />}
      {isNative && <ModalUpdateVersion />}
      {authorization && <PaymentsFrontLoader />}
      {isNative && <ModalNoInternet isVisible={internetStatus === NO_INTERNET_VIEW_LOADED} />}
    </>
  );
};

export {AppBootstrap};
