import React from 'react';
import {ALIGN, DISPLAY, FLEX_DIRECTION, useDevice, View} from '@lookiero/aurora';

import {Skeleton as NavButtonSkeleton} from '../NavButton';
import {styles} from './NavOptions.style';

export const Skeleton: React.FC = () => {
  const {screen} = useDevice();

  const buttonStyle = screen.L ? styles.navBarButtonLarge : undefined;

  return (
    <View
      display={DISPLAY.FLEX}
      flexDirection={FLEX_DIRECTION.ROW}
      justifyContent={ALIGN.AROUND}
      alignItems={ALIGN.CENTER}
      wide={!screen.L}
      style={screen.L ? styles.panelLarge : styles.panel}
    >
      <NavButtonSkeleton style={buttonStyle} />
      <NavButtonSkeleton style={buttonStyle} />
      <NavButtonSkeleton style={buttonStyle} />
      <NavButtonSkeleton style={buttonStyle} />
    </View>
  );
};
