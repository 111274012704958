import {AuthRepository} from '@domain/model/Auth/AuthRepository';
import {AuthLogin} from '@domain/model/Auth/AuthLogin';

const Login = (authRepository: AuthRepository): ((login: AuthLogin) => Promise<string | null>) => async (
  login: AuthLogin,
): Promise<string | null> => {
  const {username, password} = login;

  if (!username || !password) {
    return Promise.resolve(null);
  }
  try {
    const token = await authRepository.login(login);
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject(e);
  }
};

export {Login};
