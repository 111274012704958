import React, {FC, ReactNode} from 'react';
import {RouteObject, matchRoutes, useLocation} from 'react-router-native';

import {AppView} from '@app/App.view';
import {Navigation} from '@app/components/Navigation/Navigation';
import {Route} from '@router/Routes.config.definition';
import {useWebview} from '@app/components/Webview';

/**
 * If a new route is matched it will be rendered.
 * If not, default children will be rendered.
 *
 * Sub-routes are now supported.
 */

const matchesANewRoute = (routes: Route[], pathname: string): boolean =>
  Boolean(matchRoutes(routes as RouteObject[], pathname));

interface LegacyLayoutProps {
  readonly children: ReactNode;
  readonly routes: Route[];
}
const LegacyLayout: FC<LegacyLayoutProps> = ({children, routes}) => {
  const {pathname} = useLocation();
  const {link: isWebView} = useWebview();

  if (matchesANewRoute(routes, pathname) || isWebView) return <>{children}</>;

  console.debug(
    `You are not rendering your view ('${pathname}') using the <Layout/> component. Remember to include your route in NEW_LAYOUT_ROUTES.`,
  );

  return (
    <Navigation>
      <AppView>{children}</AppView>
    </Navigation>
  );
};

export {LegacyLayout};
