import {ConfigurationApplication} from '@application/Config';

const IMAGES_URL = (): string => `${ConfigurationApplication.retrieve().assetsPath}/images`;

const FORMAT = {PNG: 'png', JPG: 'jpg', JPEG: 'jpeg'} as const;

type ViewProps = {
  country?: string;
  screen: Partial<{S: boolean; M: boolean; L: boolean}>;
};
type ImageProps = {
  id: string;
  format?: typeof FORMAT[keyof typeof FORMAT];
  density?: '2x' | '3x';
};

const getImageURLs = ({domain}: {domain: string}) => ({country, screen = {L: false}}: ViewProps) => ({
  id,
  format = FORMAT.JPG,
  density = '2x',
}: ImageProps) => {
  const DEVICE_PATH = screen.L ? 'desktop' : 'mobile';
  const IMAGE_PATH = `${IMAGES_URL()}/${domain}${country ? `/${country}` : ''}/${DEVICE_PATH}/${id}`;

  const src = `${IMAGE_PATH}.${format}`;
  const srcSet = `${src} 1x, ${IMAGE_PATH}@2x.${format} 2x ${
    density === '3x' ? `, ${IMAGE_PATH}@3x.${format} 3x` : ''
  }`;

  return {src, srcSet};
};

export {FORMAT, IMAGES_URL, getImageURLs};
