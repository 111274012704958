import {NotificationsResponse} from 'react-native-permissions';
import {Platform} from '@domain/model/App/Platform';
import {Storage} from '@infra/api';

import {iosAdvertiserTracking} from './_helpers/advertiserTracking';

type ReactNativePermissions = typeof import('react-native-permissions');
const loadReactNativePermissions = (): Promise<ReactNativePermissions> => import('react-native-permissions');

const NOTIFICATIONS_FLAG = 'notifications.blocked';

const areNotificationsBlocked = async (): Promise<boolean> => {
  return (await Storage.get(NOTIFICATIONS_FLAG)) === 'true';
};

const setNotificationsBlocked = async (value: boolean): Promise<void> => {
  return Storage.set(NOTIFICATIONS_FLAG, value.toString());
};

export const askForNotificationPermission = async (platform: Platform): Promise<NotificationsResponse | void> => {
  const {checkNotifications, requestNotifications} = await loadReactNativePermissions();

  const {status: initialNotificationPermissionStatus} = await checkNotifications();

  const canRequestNotification = initialNotificationPermissionStatus === 'denied';

  if (canRequestNotification) {
    // NOTE: This method will handle registering a token if needed. Because
    // the status check made above should return "denied" if the token is expired,
    // This approach works with Braze and ensures tokens are fresh
    const result = await requestNotifications(['alert', 'badge', 'sound']);

    // the following is a workaround for android because it doesn't distinct between 'denied' and 'blocked'
    if (platform.android && result.status === 'denied') {
      await setNotificationsBlocked(true);
    }

    return result;
  }
};

export const askForAdvertiserTracking = async (platform: Platform): Promise<boolean> => {
  if (!platform.ios) return false;
  return await iosAdvertiserTracking();
};

const userHasBeenAskedNotifications = async (response: NotificationsResponse, platform: Platform): Promise<boolean> => {
  const {status, settings} = response;

  if (platform.ios) {
    return status === 'blocked' || (status === 'granted' && settings.alert === true);
  }

  if (platform.android) {
    const areBlocked = await areNotificationsBlocked();
    return areBlocked || status === 'granted';
  }

  return false;
};

export const checkForNotificationPermission = async (platform: Platform): Promise<boolean> => {
  const {checkNotifications} = await loadReactNativePermissions();
  const checkedValues = await checkNotifications();

  return userHasBeenAskedNotifications(checkedValues, platform);
};
