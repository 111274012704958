import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  buttonCouriers: {
    flex: 0,
  },
  step: {
    fontFamily: 'Canela-Light',
  },
  index: {
    paddingRight: '$spaceXS',
  },
  steps: {
    width: '100%',
  },
  vintedSteps: {
    borderWidth: 1,
    width: '$spaceL',
    height: '$spaceL',
    borderRadius: '$spaceL / 2',
    marginBottom: '$spaceS',
  },
});
