import React, {ReactNode} from 'react';
import {COLOR, Modal, Portal} from '@lookiero/aurora';

import {iOSInAppBrowserBannerHeight, iOSInAppBrowserVideoAd} from 'ui/helpers';
import {useMountablePortal} from '@components/hooks';

type MountableModalProps = {
  children?: React.ReactNode;
  isVisible: boolean;
  title?: string;
  description?: ReactNode;
  onClose?: () => void;
  portalID: string;
  fullHeight?: boolean;
  style?: object;
  color?: COLOR;
  size?: Record<string, string>;
  nativeID?: string;
  testID?: string;
  contentStyle?: object;
  scrollable?: boolean;
  scrollToEnd?: boolean;
};

const MountableModal = ({
  children,
  isVisible,
  nativeID,
  portalID,
  fullHeight,
  scrollable,
  style,
  contentStyle,
  scrollToEnd,
  ...rest
}: MountableModalProps): JSX.Element => {
  const {isMountedAndVisible, showComponent: showModal} = useMountablePortal({isVisible});

  /* Workaround to fix unexpected scroll behavior with modals fullHeight in FB video Ads in-app browser */
  const applyFullHeightFbFix = iOSInAppBrowserVideoAd && fullHeight;
  const fixFBFullHeightModal = applyFullHeightFbFix ? {top: iOSInAppBrowserBannerHeight} : {};
  const fixFBHeightContent = applyFullHeightFbFix ? {marginBottom: iOSInAppBrowserBannerHeight * 3} : {};
  const fixFBScrollableContent = applyFullHeightFbFix || scrollable;
  /* ***** */

  return isMountedAndVisible ? (
    <Portal id={portalID}>
      <Modal
        isVisible={showModal}
        nativeID={nativeID}
        fullHeight={fullHeight}
        scrollable={fixFBScrollableContent}
        style={[style, fixFBFullHeightModal]}
        scrollToEnd={scrollToEnd}
        contentStyle={[contentStyle, fixFBHeightContent]}
        {...rest}
      >
        {children}
      </Modal>
    </Portal>
  ) : (
    <></>
  );
};

export {MountableModal};
