import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {useIntl} from 'react-intl';
import {
  // Helpers
  ALIGN,
  LAYOUT,
  Level,
  // Components
  Link,
  View,
} from '@lookiero/aurora';

type ActionProps = {
  id: string;
  onPress: () => void;
  level?: Level | null;
  detail?: boolean;
  nativeID?: string;
  marginBottom?: LAYOUT;
  marginTop?: LAYOUT;
  testID?: string;
  style?: StyleProp<ViewStyle>;
};

export const Action = ({id, onPress, level = null, detail = false, nativeID, ...others}: ActionProps): JSX.Element => {
  const {formatMessage} = useIntl();

  return (
    <View alignItems={ALIGN.CENTER} {...others}>
      <Link
        nativeID={nativeID}
        align={ALIGN.CENTER}
        onPress={onPress}
        underlined
        {...(detail ? {detail} : {})}
        {...(level ? {level} : {})}
      >
        {formatMessage({id})}
      </Link>
    </View>
  );
};
