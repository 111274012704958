import {useEffect, useState} from 'react';
import {useShop} from '@components/_context';

export const useNotification = () => {
  const [notification, setNotification] = useState(false);
  const {canAccess: userCanAccessShop} = useShop();

  useEffect(() => {
    setNotification(userCanAccessShop);
  }, [userCanAccessShop]);

  return notification;
};
