import React from 'react';
import {Text, ALIGN} from '@lookiero/aurora';
import {useReleaseVersion} from 'ui/helpers/useReleaseVersion';

export function AppVersion(): React.ReactElement {
  const {appVersion: appVersionRaw = '', codePushVersion = ''} = useReleaseVersion();

  const [appVersion] = appVersionRaw.split('+');

  return (
    <Text align={ALIGN.CENTER} detail level={3}>
      {appVersion}
      {codePushVersion ? ` (${codePushVersion})` : null}
    </Text>
  );
}
