import PropTypes from 'prop-types';
import React from 'react';
import {
  FLEX_DIRECTION,
  LAYOUT,
  //Components
  Text,
  View,
  Button,
  AccordionContainer,
  Accordion,
} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT, COURIER} from '../ModalReturnInstructions.definition';
import {useIntl} from 'react-intl';
import {openExternalLink} from '@router/helpers';

export const ModalReturnInstructionsBRT = ({links, orderReference}) => {
  const {formatMessage} = useIntl();

  return (
    <>
      <View marginBottom={LAYOUT.S} style={style.steps}>
        <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={LAYOUT.XS}>
          <Text style={[style.step, style.index]}>1.</Text>
          <Text style={style.step}>
            {formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE, {
              courierGB: COURIER.DPD,
            })}
          </Text>
        </View>
        <View flexDirection={FLEX_DIRECTION.ROW} key={1}>
          <Text style={[style.step, style.index]}>2.</Text>
          <Text style={style.step}>
            {formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO, {
              courierGB: COURIER.DPD,
            })}
          </Text>
        </View>
      </View>
      <AccordionContainer marginBottom={LAYOUT.S} wide>
        <Accordion key={0} title={formatMessage(TEXT.ACCORDION.SEND_PICKUP.DEFAULT.TITLE)}>
          <View marginVertical={LAYOUT.S}>
            <Text as="p" key={0}>
              {formatMessage(TEXT.ACCORDION.SEND_PICKUP.DEFAULT.TEXT)}
            </Text>
          </View>
          <Button onPress={() => openExternalLink(links.searchPickupPoint)} style={style.buttonCouriers}>
            {formatMessage(TEXT.ACCORDION.SEND_PICKUP.DEFAULT.BUTTON)}
          </Button>
        </Accordion>
        <Accordion key={1} title={formatMessage(TEXT.ACCORDION.SEND_HOME.DEFAULT.TITLE)}>
          <View marginVertical={LAYOUT.S}>
            <Text as="p" key={0}>
              {formatMessage(TEXT.ACCORDION.SEND_HOME.DEFAULT.TEXT)}
            </Text>
          </View>
          <Button onPress={() => openExternalLink(links.searchPickupPoint)} style={style.buttonCouriers}>
            {formatMessage(TEXT.ACCORDION.SEND_HOME.DEFAULT.BUTTON)}
          </Button>
        </Accordion>
      </AccordionContainer>
    </>
  );
};

ModalReturnInstructionsBRT.propTypes = {
  links: PropTypes.object.isRequired,
  orderReference: PropTypes.string.isRequired,
};
