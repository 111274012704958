import React, {FC} from 'react';
import {ALIGN, Button, FLEX_DIRECTION, LAYOUT, SIZE, Text, View} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT} from '../ModalReturnInstructions.definition';
import {useIntl} from 'react-intl';

interface ModalReturnInstructionsProps {
  onClose: () => void;
}

const ModalReturnInstructionsVINTED: FC<ModalReturnInstructionsProps> = ({onClose}) => {
  const {formatMessage} = useIntl();

  return (
    <>
      <View paddingBottom={SIZE.XL} style={style.steps}>
        <View flexDirection={FLEX_DIRECTION.COLUMN} key={0} marginBottom={LAYOUT.XS} alignItems={ALIGN.CENTER}>
          <View style={style.vintedSteps} justifyContent={ALIGN.CENTER}>
            <Text action level={3} align={ALIGN.CENTER} marginLeft={SIZE.XXS}>
              1
            </Text>
          </View>
          <Text level={3} align={ALIGN.CENTER}>
            {formatMessage(TEXT.VINTED_STEPS.ONE)}
          </Text>
        </View>
        <View flexDirection={FLEX_DIRECTION.COLUMN} key={1} marginBottom={LAYOUT.XS} alignItems={ALIGN.CENTER}>
          <View style={style.vintedSteps} justifyContent={ALIGN.CENTER}>
            <Text action level={3} align={ALIGN.CENTER} marginLeft={SIZE.XXS}>
              2
            </Text>
          </View>
          <Text level={3} align={ALIGN.CENTER}>
            {formatMessage(TEXT.VINTED_STEPS.TWO)}
          </Text>
        </View>
        <Button wide onPress={onClose}>
          {formatMessage(TEXT.VINTED_STEPS.BUTTON)}
        </Button>
      </View>
    </>
  );
};

export default ModalReturnInstructionsVINTED;
