import {LocationRepository} from '@domain/model/Site/LocationRepository';
import {Country, Locale} from '@domain/model/Site/Locale';
import {SiteDomain} from '@domain/model/Site/Site';

const getStore = (repo: LocationRepository): Promise<SiteDomain> => {
  return repo.getStore();
};

const getLocale = (repo: LocationRepository): Promise<Locale> => {
  return repo.getLocale();
};

const getHostname = (repo: LocationRepository): Promise<string> => {
  return repo.getHostname();
};

const setStore = (repo: LocationRepository, site: SiteDomain): Promise<void> => {
  return repo.setStore(site);
};

const getSiteByCountry = (repo: LocationRepository, country: Country): SiteDomain => {
  return repo.getSiteByCountry(country);
};

const LocationService = (repo: LocationRepository): LocationRepository => {
  return {
    getStore: () => getStore(repo),
    getLocale: () => getLocale(repo),
    getHostname: () => getHostname(repo),
    setStore: (site: SiteDomain) => setStore(repo, site),
    getSiteByCountry: (country: Country) => getSiteByCountry(repo, country),
  };
};

export {LocationService};
