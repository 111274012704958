import {LAYOUT} from '@lookiero/aurora';

export const calcMarginRight = ({index, itemsPerRow, numberOfItems}) =>
  itemsPerRow
    ? (index + 1) % itemsPerRow !== 0
      ? LAYOUT.S
      : undefined
    : index + 1 < numberOfItems
    ? LAYOUT.S
    : undefined;
