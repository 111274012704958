import PropTypes from 'prop-types';
import React from 'react';
import {
  FLEX_DIRECTION,
  LAYOUT,
  //Components
  Text,
  View,
  Button,
  AccordionContainer,
  Accordion,
} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT, DEFAULT_COUNTRY, COURIER} from '../ModalReturnInstructions.definition';
import {useIntl} from 'react-intl';
import {openExternalLink} from '@router/helpers';

export const ModalReturnInstructionsMR = ({links, country = DEFAULT_COUNTRY, orderReference}) => {
  const {formatMessage} = useIntl();

  function renderSpain() {
    return (
      <>
        <View marginBottom={LAYOUT.S} style={style.steps}>
          <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={LAYOUT.XS}>
            <Text style={[style.step, style.index]}>1.</Text>
            <Text style={style.step}>
              {formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE, {
                courierGB: COURIER.HERMES,
              })}
            </Text>
          </View>
          <View flexDirection={FLEX_DIRECTION.ROW} key={1}>
            <Text style={[style.step, style.index]}>2.</Text>
            <Text style={style.step}>
              {formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO, {
                courierGB: COURIER.HERMES,
              })}
            </Text>
          </View>
        </View>
        <AccordionContainer marginBottom={LAYOUT.S} wide>
          <Accordion key={0} title={formatMessage(TEXT.ACCORDION.SEND_PICKUP.DEFAULT.TITLE)}>
            <View marginVertical={LAYOUT.S}>
              <Text as="p" key={0}>
                {formatMessage(TEXT.ACCORDION.SEND_PICKUP.MR.TEXT)}
              </Text>
            </View>
            <Button onPress={() => openExternalLink(links.searchPickupPoint)}>
              {formatMessage(TEXT.ACCORDION.SEND_PICKUP.DEFAULT.BUTTON)}
            </Button>
          </Accordion>
          <Accordion key={1} title={formatMessage(TEXT.ACCORDION.SEND_HOME.DEFAULT.TITLE)}>
            <View marginVertical={LAYOUT.S}>
              <Text as="p" key={1}>
                {formatMessage(TEXT.ACCORDION.SEND_HOME.MR.TEXT)}
              </Text>
            </View>
            <Button onPress={() => openExternalLink(links.alternativeRequestPickup)}>
              {formatMessage(TEXT.ACCORDION.SEND_HOME.DEFAULT.BUTTON)}
            </Button>
          </Accordion>
        </AccordionContainer>
      </>
    );
  }

  function renderForeignCourier() {
    return (
      <>
        <View style={style.steps} marginBottom={LAYOUT.S}>
          <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={LAYOUT.XS}>
            <Text style={[style.step, style.index]}>1.</Text>
            <Text style={style.step}>
              {formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE, {
                courierGB: COURIER.HERMES,
              })}
            </Text>
          </View>
          <View flexDirection={FLEX_DIRECTION.ROW} key={1} marginBottom={LAYOUT.XS}>
            <Text style={[style.step, style.index]}>2.</Text>
            <Text style={style.step}>
              {formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO, {
                courierGB: COURIER.HERMES,
              })}
            </Text>
          </View>
          {orderReference !== undefined && (
            <View flexDirection={FLEX_DIRECTION.ROW} key={2}>
              <Text style={[style.step, style.index]}>3.</Text>
              <Text style={style.step}>{formatMessage(TEXT.STEPS.THREE, {courierGB: COURIER.HERMES})}</Text>
            </View>
          )}
        </View>
        <Button
          onPress={() => openExternalLink(links.searchPickupPoint)}
          marginBottom={LAYOUT.S}
          testID={`find-drop-off-button`}
        >
          {formatMessage(TEXT.RETURN_BUTTON)}
        </Button>
      </>
    );
  }

  return (
    <>
      {country === 'ES' && renderSpain()}
      {country !== 'ES' && renderForeignCourier()}
    </>
  );
};

ModalReturnInstructionsMR.propTypes = {
  links: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  orderReference: PropTypes.string.isRequired,
};
