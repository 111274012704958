import StyleSheet from 'react-native-extended-stylesheet';

const style = StyleSheet.create({
  logo: {
    marginBottom: '$layoutXS',
  },
  logoTiny: {
    marginTop: '$layoutS * -1',
  },
  logoMedium: {
    marginTop: '$layoutM * -1',
  },
});

export {style};
