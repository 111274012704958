import StyleSheet from 'react-native-extended-stylesheet';

const menuMobileHeight = 56;
const menuDesktopHeight = 48;

const style = StyleSheet.create({
  navBarContainer: {
    width: '100%',
    borderBottomWidth: '$borderSize',
    borderColor: '$colorGrayscaleS',
    height: menuMobileHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '$colorBase',
  },
  navBarLarge: {
    height: menuDesktopHeight,
  },
});

export {style, menuMobileHeight, menuDesktopHeight};
