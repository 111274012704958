import {useEffect} from 'react';

import {useEvent} from '@lookiero/event';
import {PaymentsError} from '@lookiero/payments-front';
import {sendException} from 'errors/errorLogger';

const errorParser = (error: any): any => {
  const {error: nestedError} = error;

  if (nestedError) {
    if (nestedError instanceof PaymentsError) return nestedError;
    return new PaymentsError(nestedError);
  }

  return error;
};

export const useSentryForPayments = (): void => {
  const {subscribe, unsubscribe} = useEvent();

  const notifySentry = (error: unknown): void => {
    sendException(errorParser(error));
  };

  useEffect(() => {
    subscribe({domain: 'PAYMENTS', event: 'MONITORING.ERROR'}, notifySentry);

    return () => unsubscribe({domain: 'PAYMENTS', event: 'MONITORING.ERROR'}, notifySentry);
  }, [subscribe, unsubscribe]);
};
