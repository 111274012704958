import {Image, SafeAreaView} from '@lookiero/aurora';
import React from 'react';

import {style} from './Loading.styles';
import {ConfigurationApplication} from '@application/Config';

export const Loading = () => (
  <SafeAreaView>
    <Image
      src={`${ConfigurationApplication.retrieve().commonAssetsPath}/images/lookiero-loading-content.gif`}
      style={style.loading}
    />
  </SafeAreaView>
);
