import {menuMobileHeight} from '@components/molecules/NavigationBar/NavigationBar.style';
import {StyleSheet} from 'react-native';

const style = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
  },
  scroller: {
    paddingTop: menuMobileHeight,
  },
});

export {style};
