import React, {FC, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {ALIGN, Button, LAYOUT, Text, useDevice} from '@lookiero/aurora';

import {AppApplication} from '@application/App';
import {MountableModal} from '@components/molecules';
import {useModal, MODAL_STATUS} from '@components/_context/ModalManager';
import {useStore} from '@components/hooks';
import {AppEvent} from '@domain/model/Event';
import {VersionStatus} from '@domain/model/App/VersionStatus';
import {Platform} from '@domain/model/App/Platform';

import {openExternalLink} from '@router';
import {eventManager} from '@application/Event';
import {useReleaseVersion} from '@helpers';

import {Action} from '../Action';
import {style} from './ModalUpdateVersion.styles';
import {TEXT} from './ModalUpdateVersion.definition';

type NativePlatform = 'android' | 'ios';

const getPlatform = (platform: Platform): NativePlatform => (platform.ios ? 'ios' : 'android');

const ModalUpdateVersion: FC = () => {
  const {platform} = useDevice();
  const {formatMessage} = useIntl();
  const {nativeStore} = useStore();
  const {appVersion} = useReleaseVersion();

  const [versionStatus, setVersionStatus] = useState<VersionStatus>();

  const {isOpen, closeModal, openModal} = useModal('update-version', MODAL_STATUS.LOADING);

  useEffect(() => {
    const getVersionStatus = async (): Promise<void> => {
      try {
        const versionStatus = await AppApplication.getVersionStatus({
          platform: getPlatform(platform),
          version: appVersion as string,
        });
        setVersionStatus(versionStatus);
        openModal();
      } catch (error) {
        closeModal();
      }
    };

    if (appVersion) getVersionStatus();
  }, [openModal, closeModal, platform, appVersion]);

  useEffect(() => {
    if (isOpen) {
      eventManager.emit(AppEvent.SHOW_UPDATE_VERSION);
    }
  }, [isOpen]);

  const openStore = (): void => {
    eventManager.emit(AppEvent.ACCEPT_UPDATE_VERSION);
    openExternalLink(nativeStore);
  };

  const onClose = (): void => {
    eventManager.emit(AppEvent.SKIP_UPDATE_VERSION);
    closeModal();
  };

  return (
    <MountableModal
      isVisible={isOpen}
      portalID="update-version"
      onClose={versionStatus === VersionStatus.RECOMMEND ? onClose : undefined}
    >
      <Text
        align={ALIGN.CENTER}
        level={1}
        marginBottom={LAYOUT.XXS}
        marginTop={versionStatus === VersionStatus.RECOMMEND ? LAYOUT.XS : undefined}
      >
        {formatMessage(TEXT.TITLE)}
      </Text>
      <Text align={ALIGN.CENTER} level={2} marginBottom={LAYOUT.S}>
        {formatMessage(TEXT.DESCRIPTION)}
      </Text>
      <Button style={style.button} onPress={openStore} marginBottom={LAYOUT.XXS}>
        {formatMessage(TEXT.CTA_YES)}
      </Button>
      {versionStatus === VersionStatus.RECOMMEND && (
        <Action {...TEXT.CTA_NO} onPress={onClose} marginBottom={LAYOUT.XXS} marginTop={LAYOUT.XXS} />
      )}
    </MountableModal>
  );
};

export {ModalUpdateVersion};
