import {AuthRepository} from '@domain/model/Auth/AuthRepository';

const RecoverPassword = (
  authRepository: AuthRepository,
): (({email}: {email: string}) => Promise<Record<string, string> | null>) => ({
  email,
}: {
  email: string;
}): Promise<Record<string, string> | null> => {
  if (!email) {
    return Promise.resolve(null);
  }

  return authRepository.recoverPassword({email});
};

export {RecoverPassword};
