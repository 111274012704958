import {fetch} from '@infra/api/fetch';
import {ReferralInfo} from '@raf/core/domain/ReferralInfo';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const getReferralInfoByCode = async (code: string, country: string): Promise<ReferralInfo | null> => {
  const requestOptions = {
    method: 'GET',
    headers: DEFAULT_HEADERS,
  };

  try {
    const response = await fetch(`/api/v2/referral-info?referralCode=${code}&country=${country}`, requestOptions);
    const {referral_name: referralName, referred_reward: referredReward, enabled} = await response.json();

    return Promise.resolve({referralName, referredReward, enabled});
  } catch (e) {
    return Promise.reject(e);
  }
};

const HttpRepository = {getReferralInfoByCode};

export {HttpRepository};
