import React, {useContext, useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';

import {SiteApplication} from '@application/Site';
import {useLocale} from '@components/hooks';

const settingsInitialValue = {giftcardAvailable: false, loading: false};

const SettingsContext = React.createContext(settingsInitialValue);

const SettingsProvider = ({children}) => {
  const {country} = useLocale();

  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState({});

  const getSettingsByCountry = async country => {
    setLoading(true);

    let settings = await SiteApplication.getSettingsByCountry(country);
    settings = {
      ...settings,
      postCodeRegex: new RegExp(settings.postCodeRegex),
      phoneRegex: new RegExp(settings.phoneRegex),
    };

    setSettings(prevSettings => (prevSettings.site === settings.site ? prevSettings : settings));
    setLoading(false);
  };

  useEffect(() => {
    if (country) getSettingsByCountry(country);
  }, [country]);

  const value = useMemo(() => ({...settings, updateSettingsByCountry: getSettingsByCountry, loading}), [
    loading,
    settings,
  ]);

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

const useSettings = () => useContext(SettingsContext) || {};

export {useSettings, SettingsProvider, SettingsContext};
