import {executeQuery} from '@infra/api/graphQL/client';
import {Queries} from '@infra/api/graphQL/domain/model/Referral/queries';
import {Referral} from '@raf/core/domain/Referral';
import {ReferralForShare} from '@raf/core/domain/ReferralForShare';

type ReferralResponse = Record<'rafInfo', Referral>;

const getReferral = async (): Promise<Referral> => {
  try {
    const data = await executeQuery<ReferralResponse>(Queries.GET_REFERRAL_QUERY);
    return Promise.resolve(data.rafInfo);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getReferralForShare = async (): Promise<ReferralForShare> => {
  try {
    const data = await executeQuery<ReferralResponse>(Queries.GET_REFERRAL_QUERY);
    return Promise.resolve(data.rafInfo);
  } catch (error) {
    return Promise.reject(error);
  }
};

const GraphqlRepository = {getReferral, getReferralForShare};

export {GraphqlRepository};
