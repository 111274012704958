import React, {useContext, createContext, useState, useEffect, useMemo, useCallback} from 'react';

export const MODAL_STATUS = {LOADING: 'loading', OPEN: 'open', CLOSED: 'closed'} as const;
type ModalStatus = typeof MODAL_STATUS[keyof typeof MODAL_STATUS];

type ModalManager = {
  currentModal: string | undefined;
  areModalsClosed: boolean;
  openModal: (id: string) => void;
  closeModal: (id: string) => void;
  loadingModal: (id: string) => void;
  registerModal: (id: string, status: ModalStatus) => void;
  deregisterModal: (id: string) => void;
};

type ModalHook = {
  isOpen: boolean;
  areModalsClosed: boolean;
  openModal: () => void;
  closeModal: () => void;
  loadingModal: () => void;
};

type Modal = {id: string; status: ModalStatus};

const ModalManagerContext = createContext<ModalManager | undefined>(undefined);

const updateModalStatus = (id: string, status: ModalStatus) => (list: Modal[]) => {
  return list.map(item => {
    if (item.id === id) {
      return {id: item.id, status};
    }
    return item;
  });
};

export const ModalManagerProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [modalList, setModalList] = useState<Modal[]>([]);

  const currentModal = useMemo(() => {
    for (const modal of modalList) {
      if (modal.status === MODAL_STATUS.LOADING) return undefined;
      if (modal.status === MODAL_STATUS.OPEN) return modal.id;
    }
    return undefined;
  }, [modalList]);

  const registerModal = useCallback((id: string, status: ModalStatus) => {
    setModalList(current => {
      if (!current.find(modal => modal.id === id)) {
        return [...current, {id, status}];
      }
      return current;
    });
  }, []);

  const deregisterModal = useCallback((id: string) => {
    setModalList(current => current.filter(modal => modal.id !== id));
  }, []);

  const loadingModal = useCallback((id: string) => {
    setModalList(updateModalStatus(id, MODAL_STATUS.LOADING));
  }, []);

  const openModal = useCallback((id: string) => {
    setModalList(updateModalStatus(id, MODAL_STATUS.OPEN));
  }, []);

  const closeModal = useCallback((id: string) => {
    setModalList(updateModalStatus(id, MODAL_STATUS.CLOSED));
  }, []);

  const areModalsClosed = !modalList.length || modalList.every(modal => modal.status === MODAL_STATUS.CLOSED);

  return (
    <ModalManagerContext.Provider
      value={{areModalsClosed, currentModal, deregisterModal, registerModal, loadingModal, openModal, closeModal}}
    >
      {children}
    </ModalManagerContext.Provider>
  );
};

export const useModalManager = (): ModalManager => {
  const context = useContext(ModalManagerContext);

  if (context === undefined) {
    throw new Error('useModalManager must be used within a ModalManagerProvider');
  }

  return context;
};

export const useModal = (id: string, initialStatus: ModalStatus): ModalHook => {
  const {
    currentModal,
    areModalsClosed,
    deregisterModal,
    registerModal,
    openModal,
    closeModal,
    loadingModal,
  } = useModalManager();

  useEffect(() => {
    registerModal(id, initialStatus);
    return () => deregisterModal(id);
  }, [deregisterModal, id, initialStatus, registerModal]);

  return {
    isOpen: currentModal === id,
    areModalsClosed,
    openModal: useCallback(() => openModal(id), [openModal, id]),
    closeModal: useCallback(() => closeModal(id), [closeModal, id]),
    loadingModal: useCallback(() => loadingModal(id), [loadingModal, id]),
  };
};
