import React, {FC} from 'react';
import {ALIGN, LAYOUT, Button, Text, View, Icon, FLEX_DIRECTION} from '@lookiero/aurora';
import {useIntl} from 'react-intl';
import {TEXT} from './index.definition';
import {MountableModal} from '@components/molecules';
import {style} from './RequestNotification.styles';

type RequestNotificationProps = {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

// eslint-disable-next-line react/prop-types
const CenteredText: FC<{icon: string; children: React.ReactNode}> = ({icon, children}) => (
  <View marginBottom={LAYOUT.S} flexDirection={FLEX_DIRECTION.ROW} marginRight={LAYOUT.XS} marginLeft={LAYOUT.XS}>
    <Icon name={icon} marginRight={LAYOUT.XS} />
    <Text detail style={{paddingTop: 0}} level={1} align={ALIGN.CENTER}>
      {children}
    </Text>
  </View>
);

// eslint-disable-next-line react/prop-types
export const RequestNotification: FC<RequestNotificationProps> = ({isVisible, onClose, onConfirm}) => {
  const {formatMessage} = useIntl();

  return (
    <MountableModal
      isVisible={isVisible}
      portalID="request-notification"
      onClose={onClose}
      title={formatMessage(TEXT.TITLE)}
    >
      <View marginTop={LAYOUT.XXS} marginBottom={LAYOUT.XXS}>
        <CenteredText icon="package">{formatMessage(TEXT.FEATURE_1)}</CenteredText>
        <CenteredText icon="heart">{formatMessage(TEXT.FEATURE_2)}</CenteredText>
        <CenteredText icon="prime">{formatMessage(TEXT.FEATURE_3)}</CenteredText>
        <CenteredText icon="eye-open">{formatMessage(TEXT.FEATURE_4)}</CenteredText>
      </View>
      <Button testID="REQUEST_NOTIFICATION_MODAL_ACCEPT_BUTTON" onPress={onConfirm} style={style.modalButton}>
        {formatMessage(TEXT.NEXT)}
      </Button>
    </MountableModal>
  );
};
