import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '$colorBase',
    height: 48, // avoid using token because of tiny theme
    paddingHorizontal: '$spaceS',
  },
  title: {
    left: '$spaceXXXL',
    right: '$spaceXXXL',
    alignSelf: 'center',
  },
  backButton: {
    color: '$colorContent',
    justifyContent: 'center',
    alignSelf: 'center',
  },
});
