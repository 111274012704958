import React from 'react';

import {useApp} from '@app';
import {useNotification} from '@app/useNotification';
import {useNavigationBar} from '@components/hooks/useNavigationBar';
import {BurgerBar} from '@components/molecules/BurgerBar';
import {SubNavigation} from '@components/molecules/SubNavigation';
import {NavigationBar} from '@components/molecules/NavigationBar';
import {NavWrapper} from '@components/molecules/NavigationBar/NavWrapper';

type LegacyHeaderProps = {
  hideBurgerBar?: boolean;
  hasSectionBar?: boolean;
};

const Header: React.FC<LegacyHeaderProps> = ({hideBurgerBar = false, hasSectionBar = false}) => {
  const {header = {}, authorization, loading, togglePanelVisibility} = useApp();
  const notification = useNotification();
  const isNavigationBarEnabled = useNavigationBar();

  const {
    onBack = undefined,
    showMenu = true,
    title = undefined,
    visible = true,
    banner = undefined,
    headerChildren = undefined,
    showLogo = true,
    unmount = false,
  } = header;

  const busy = authorization === undefined || loading;
  const isSubNavigation = onBack || title || headerChildren;
  const hideMenu = !showMenu;
  const hideLogo = !showLogo;

  if (!visible || unmount) return null;

  if (isSubNavigation) {
    return (
      <SubNavigation onBack={onBack} title={title}>
        {headerChildren}
      </SubNavigation>
    );
  }

  if (isNavigationBarEnabled) {
    return (
      <NavWrapper hasSectionBar={hasSectionBar}>
        <NavigationBar busy={busy} hideMenu={hideMenu} banner={banner} />
      </NavWrapper>
    );
  }

  return !hideBurgerBar ? (
    <BurgerBar
      busy={busy}
      hideMenu={hideMenu}
      hideLogo={hideLogo}
      notification={notification}
      banner={banner}
      onPressMenu={() => togglePanelVisibility(true)}
    />
  ) : null;
};

export {Header};
