import {ROUTES} from '@router/Routes.config';
import {Route} from '@router/Routes.config.definition';

const COMMON_NEW_LAYOUT_ROUTES: Route[] = [
  ROUTES.LOGIN,
  ROUTES.RECOVER_PASSWORD,
  ROUTES.REFERRALS_LANDING,
  ROUTES.RETURN_ORDER,
  ROUTES.REFERRALS,
  ROUTES.ACCOUNT,
  ROUTES.ACCOUNT_MY_PLAN,
  ROUTES.PROFILE_MENU,
  ROUTES.CHECKOUT,
  ROUTES.QUIZ_HOME,
  ROUTES.QUIZ,
  ROUTES.STYLE_PROFILE,
  ROUTES.STYLE_HOME,
  ROUTES.STYLE,
  ROUTES.SHOP,
  ROUTES.HOME,
];

export {COMMON_NEW_LAYOUT_ROUTES};
