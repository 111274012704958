import {useIntl} from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {LAYOUT} from '@lookiero/aurora';

import {emptyFn, getFieldHint, hasFieldError} from '@helpers';

const FormField = ({
  uniqueKey,
  label: {key} = {},
  component: Component,
  marginBottom = LAYOUT.XS,
  marginTop,
  handleBlur = emptyFn,
  handleChange = emptyFn,
  handleUserInput = emptyFn,
  getFieldMeta = emptyFn,
  values = {},
  type,
  forceShowErrors = false,
  ...rest
}) => {
  const {touched, error: metaError, initialValue} = getFieldMeta(uniqueKey);

  const {formatMessage} = useIntl();

  return (
    <Component
      name={uniqueKey}
      label={formatMessage({id: key})}
      onChange={handleChange(uniqueKey)}
      onBlur={handleBlur(uniqueKey)}
      value={values[uniqueKey]}
      data-testid={`${uniqueKey}-field`}
      defaultValue={initialValue}
      onKeyUp={handleUserInput}
      onPaste={handleUserInput}
      {...{
        error: hasFieldError({touched, error: metaError, forceShowErrors}),
        hint: getFieldHint({touched, error: metaError, forceShowErrors}),
        marginBottom,
        marginTop,
        type,
      }}
      {...rest}
    />
  );
};

FormField.propTypes = {
  uniqueKey: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleUserInput: PropTypes.func,
  getFieldMeta: PropTypes.func.isRequired,
  values: PropTypes.object,
  type: PropTypes.string,
  forceShowErrors: PropTypes.bool,
};

export {FormField};
