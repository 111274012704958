import React, {useState} from 'react';
import {Motion, POINTER, POSITION, SIZE, View} from '@lookiero/aurora';
import {Loading} from '@components/atoms';
import {Logo as LookieroLogo} from 'assets/Logo';

type NavLogoProps = {
  busy?: boolean;
};

export const NavLogo: React.FC<NavLogoProps> = ({busy = false}) => {
  const [firstLoad, setFirstLoad] = useState(true);

  if (!busy && firstLoad) {
    setFirstLoad(false);
  }

  return (
    <View testID="navbar-logo">
      <Motion
        disabled={firstLoad}
        layer={SIZE.L}
        pointer={POINTER.NONE}
        position={POSITION.ABSOLUTE}
        value={{opacity: firstLoad ? 1 : 0}}
      >
        <Loading />
      </Motion>
      {!firstLoad && <LookieroLogo />}
    </View>
  );
};
