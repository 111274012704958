import React, {FC} from 'react';
import {Navigate, To, useLocation} from 'react-router-native';
import {Path} from './Routes.config.definition';
import {useApp} from '@app';

export interface NavigateToFallbackProps {
  readonly authorisedFallback: Path;
  readonly unauthorisedFallback: Path;
  readonly replaceOnNavigate: boolean;
}
export const NavigateToFallback: FC<NavigateToFallbackProps> = ({
  authorisedFallback,
  replaceOnNavigate,
  unauthorisedFallback,
}) => {
  const {authorization} = useApp();
  const {pathname, search} = useLocation();

  const fallbackDestination: To = authorization
    ? {pathname: authorisedFallback}
    : {
        pathname: unauthorisedFallback,
        ...(pathname !== '/' ? {search: '?redirect=' + encodeURIComponent(pathname + search)} : {}),
      };

  return <Navigate to={fallbackDestination} replace={replaceOnNavigate} />;
};
