import {Auth} from '@domain/model/Auth/Auth';
import {AuthLoginGoogle} from '@domain/model/Auth/AuthLoginGoogle';
import {RegisterUserCommand, RegisterUserCommandGoogle} from '@domain/model/Auth/RegisterUserCommand';
import {setAuth} from '@infra/api/authManager/authManager';
import {fetch} from '@infra/api/fetch';

const recoverPassword = async ({email}: {email: string}): Promise<Record<string, string>> => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({email}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  const response = await fetch(`/api/auth/forgotpwd/`, requestOptions);
  if (response.status === 404) {
    return Promise.reject({errorKey: response.status});
  }

  return response.json();
};

const registerUser = async (registerUserCommand: RegisterUserCommand): Promise<Auth> => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(registerUserCommand),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  const response = await fetch(`/api/register/`, requestOptions);
  if (!response.ok) {
    let code, message;
    try {
      ({code, message} = await response.json());
    } catch (e) {
      code = '';
      message = '';
    }
    return Promise.reject({errorKey: response.status, code, message});
  }
  return response.json();
};
const registerUserGoogle = async (registerUserCommand: RegisterUserCommandGoogle): Promise<Auth> => {
  // deletes undefined data from command (i.e: tokenId on web and googleCode in native)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const registerData = Object.fromEntries(Object.entries(registerUserCommand).filter(([_, v]) => v !== undefined));
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(registerData),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  const response = await fetch(`/api/v1/register-google/`, requestOptions);
  if (!response.ok) {
    let code, message;
    try {
      ({code, message} = await response.json());
    } catch (e) {
      code = '';
      message = '';
    }
    return Promise.reject({errorKey: response.status, code, message});
  }
  return response.json();
};

const loginWithGoogle = async (loginCommand: AuthLoginGoogle): Promise<string> => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(loginCommand),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  const response = await fetch(`/api/v1/login-google`, requestOptions);

  if (!response.ok) {
    return Promise.reject({errorKey: response.status});
  }

  const {token} = await response.json();
  await setAuth(token);
  return Promise.resolve(token);
};

const apiAuth = {recoverPassword, loginWithGoogle, registerUser, registerUserGoogle};

export {apiAuth};
