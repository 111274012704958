import StyleSheet from 'react-native-extended-stylesheet';

const style = StyleSheet.create({
  optionGap: {
    marginBottom: '$spaceS',
  },
  option: {
    flexDirection: 'row',
    width: '100%',
    padding: '$spaceM',
    borderWidth: '$borderSize',
    borderStyle: '$borderStyle',
    alignItems: 'center',
  },
  unselectedOption: {
    borderColor: '$colorGrayscaleL',
  },
  unselectedText: {
    color: '$colorGrayscaleL',
  },
  check: {
    marginRight: '$spaceM',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: '$colorPrimary',
    marginLeft: '$spaceS',
  },
  description: {
    marginTop: '$spaceXS',
  },
});

export {style};
