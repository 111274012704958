import {Footer, FooterItem} from '@lookiero/aurora';
import React, {FC} from 'react';
import {SafeAreaView} from 'react-native';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-native';
import {useApp} from '@app';
import {INTERNET_OKAY, useInternetStatus} from '@app/components/NoInternet';
import {useWebview} from '@app/components/Webview';
import {Path} from '@router/Routes.config.definition';
import {useHasUnreadInboxContentCards} from './useHasUnreadInboxContentCards.native';
import {TEXT} from './Tabbar.definition';
import {style} from './TabBar.styles';
import {useShop} from '@components/_context';

interface HandleChangeFunction {
  (routeName: string): void;
}

interface TabBarProps {
  readonly hidden: boolean;
}
const TabBar: FC<TabBarProps> = ({hidden}) => {
  const {loading} = useApp();
  const {canAccess} = useShop();
  const {pathname} = useLocation();
  const hasUnreadInboxContentCards = useHasUnreadInboxContentCards(pathname);
  const {formatMessage} = useIntl();
  const internetStatus = useInternetStatus({loading});
  const navigate = useNavigate();
  const {close} = useWebview();

  const handleChange: HandleChangeFunction = routeName => {
    if (internetStatus !== INTERNET_OKAY) return;

    close();
    navigate(routeName);
  };

  return hidden ? (
    <></>
  ) : (
    <SafeAreaView>
      <Footer
        style={style.container}
        value={internetStatus === INTERNET_OKAY ? pathname : undefined}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={handleChange}
      >
        <FooterItem icon="box" text={formatMessage(TEXT.HOME)} value={Path.HOME} />
        <FooterItem icon="heart" text={formatMessage(TEXT.LOOK_AND_LIKE)} value={Path.LOOK_AND_LIKE} />
        <FooterItem icon="faces" text={formatMessage(TEXT.FRIENDS)} value={Path.REFERRALS} />
        {canAccess ? <FooterItem icon="tag" text={formatMessage(TEXT.SHOP)} value={Path.SHOP_HOME} /> : null}
        <FooterItem
          icon="inbox"
          text={formatMessage(TEXT.MESSAGES)}
          value={Path.INBOX}
          notification={hasUnreadInboxContentCards}
        />
      </Footer>
    </SafeAreaView>
  );
};

export {TabBar};
