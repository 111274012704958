import React from 'react';
import {GestureResponderEvent, ViewStyle} from 'react-native';
import {AnyObject} from 'react-native-extended-stylesheet';
import {Icon, Text, Touchable, useDevice, Image} from '@lookiero/aurora';
import {IMAGES_URL} from '@cdn';

import {styles} from './NavButton.styles';

type NavButtonProps = {
  text: string;
  icon?: 'mybox' | 'tag' | 'hanger' | 'faces' | 'profile' | 'gift';
  image?: {
    name: string;
    style?: AnyObject | AnyObject[];
  };
  style?: ViewStyle;
  active?: boolean;
  onPress: (event: GestureResponderEvent) => void;
  testID?: string;
};

const getImagesPath = (): string => IMAGES_URL() + '/navigation/';

const getFileName = (fileName: string, active?: boolean): string => `${fileName}${active ? '-filled' : ''}.svg`;

export const NavButton: React.FC<NavButtonProps> = ({icon, active, text, style, onPress, image, testID}) => {
  const {screen} = useDevice();

  const buttonStyle = screen.L ? styles.buttonDesktop : styles.buttonMobile;
  const iconStyle = screen.L ? styles.iconDesktop : undefined;

  return (
    <Touchable style={[style, buttonStyle]} onPress={onPress} testID={testID}>
      {icon && <Icon name={active ? `${icon}-filled` : icon} style={iconStyle} />}
      {image && (
        <Image src={getImagesPath() + getFileName(image.name, active)} style={[styles.image, iconStyle, image.style]} />
      )}
      <Text detail level={2}>
        {text}
      </Text>
    </Touchable>
  );
};
