import {AppRepository} from '@domain/model/App/AppRepository';
import {VersionStatus} from '@domain/model/App/VersionStatus';
import {GetVersionStatusCommand} from '@domain/model/App/GetVersionStatusCommand';

const GetVersionStatus = (appRepository: AppRepository) => ({
  platform,
  version,
}: GetVersionStatusCommand): Promise<VersionStatus> => {
  return appRepository.getVersionStatus({platform, version});
};

export {GetVersionStatus};
