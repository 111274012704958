import React from 'react';
interface AvoidKeyboardContextApi {
  readonly setAvoidKeyboard: (avoid: boolean) => void;
}
interface KeyboardAvoidingViewProps {
  readonly children: React.ReactNode;
}
export const useAvoidKeyboard = (): AvoidKeyboardContextApi => ({
  setAvoidKeyboard: () => void 0,
});

export const KeyboardAvoidingView: React.FC<KeyboardAvoidingViewProps> = ({children}) => <>{children}</>;
