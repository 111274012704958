import {SiteDTO} from '@domain/model/Site/Site';
import {Locale} from '@domain/model/Site/Locale';
import {Storage} from '@infra/api';
import {fetch} from '@infra/api/fetch';
import {sendException} from 'errors/errorLogger';

const STORAGE_KEY = 'dictionary';

const LOCALISE_PROJECTS = [
  'user-area-front',
  'promocodes',
  'style-profile',
  'shop',
  'inventory-catalog',
  'payments-front',
];

const translationParams = LOCALISE_PROJECTS.map(project => `projectFilter=${project}`).join('&');

const getMessagesByLocale = async ({language, country}: Locale): Promise<Record<string, string>> => {
  return fetch(`/api/v2/translations/${language}/${country}?${translationParams}`)
    .then(async response => {
      const dictionary = await response.json();
      await Storage.set(STORAGE_KEY, JSON.stringify(dictionary));

      return dictionary;
    })
    .catch(async error => {
      sendException(new Error(`Failed to get translations ${language}/${country}, error: ${error.message}`));
      const storedDictionary = await Storage.get(STORAGE_KEY);

      return storedDictionary ? JSON.parse(storedDictionary) : {};
    });
};

const getSettingsByCountry = async (country: string): Promise<SiteDTO | null> => {
  return fetch(`/api/site/${country}`)
    .then(response => response.json())
    .catch(error => {
      sendException(new Error(`Failed to site configuration for ${country}, error: ${error.message}`));
      return null;
    });
};

const apiSite = {getMessagesByLocale, getSettingsByCountry};

export {apiSite};
