import {ReferralRepository} from '@raf/core/domain/ReferralRepository';

type GetReferralInfoByCodeType = (repository: ReferralRepository) => ReferralRepository['getReferralInfoByCode'];

const GetReferralInfoByCode: GetReferralInfoByCodeType = repository => ({code, country}) => {
  if (!code) return Promise.resolve(null);
  return repository.getReferralInfoByCode({code, country});
};

export {GetReferralInfoByCode};
