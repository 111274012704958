import {ReferralRepository} from '@raf/core/domain/ReferralRepository';
import {sendException} from 'errors/errorLogger';

import {HttpRepository} from '../HttpRepository';
import {GraphqlRepository} from '../GraphqlRepository';

const getReferral: ReferralRepository['getReferral'] = async () => {
  return GraphqlRepository.getReferral();
};

const getReferralForShare: ReferralRepository['getReferralForShare'] = async () => {
  return GraphqlRepository.getReferralForShare();
};

const getReferralInfoByCode: ReferralRepository['getReferralInfoByCode'] = async ({code, country}) => {
  try {
    const data = await HttpRepository.getReferralInfoByCode(code, country);
    return Promise.resolve(data);
  } catch (e) {
    sendException(e);
    return Promise.reject(e);
  }
};

export const ApiReferralRepository = {
  getReferral,
  getReferralForShare,
  getReferralInfoByCode,
};
