import React, {useContext, useMemo, useState, useEffect} from 'react';

import {FeatureFlagApplication} from '@application/FeatureFlag';

const FeatureFlagsContext = React.createContext({});

type FeatureFlagsDictionary = {[key: string]: boolean};

const getFeatureFlags = async (): Promise<FeatureFlagsDictionary> => {
  const featureFlagList = await FeatureFlagApplication.getFeatureFlags();

  return featureFlagList.reduce(
    (accumulator, featureFlag) => {
      accumulator[featureFlag.name] = featureFlag.enabled;
      return accumulator;
    },
    {} as FeatureFlagsDictionary,
  );
};

const FeatureFlagsProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [featureFlagsHash, setFeatureFlags] = useState({});

  const featureFlags = useMemo(() => featureFlagsHash, [featureFlagsHash]);

  useEffect(() => {
    getFeatureFlags().then(ffs => setFeatureFlags(ffs));
  }, []);

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
};

const useFeatureFlags = (): FeatureFlagsDictionary => {
  const context = useContext(FeatureFlagsContext);

  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsContext');
  }

  return context;
};

export {useFeatureFlags, FeatureFlagsProvider};
