const experiments = {
  NEW_NAVIGATION: {
    name: 'new_navigation_exp_2',
    variants: {
      control: 'new_navigation_exp_2_control',
      v1: 'new_navigation_exp_2_v1',
    },
  },
  APP_DOWNLOAD_MODAL: {
    name: 'modal_download_app_postorder_MyLK',
    variants: {
      control: 'modal_download_app_postorder_MyLK_control',
      v1: 'modal_download_app_postorder_MyLK_v1',
    },
  },
  GOOGLE_REGISTER: {
    name: 'google_register',
    variants: {
      control: 'google_register_control',
      v1: 'google_register_v1',
    },
  },
  RAF_TRIGGER_FLOW: {
    name: 'RAF_experiment_trigger_flow',
    variants: {
      control: 'RAF_experiment_trigger_flow_control',
      v1: 'RAF_experiment_trigger_flow_v1',
    },
  },
  RAF_SHARE_2: {
    name: 'RAF_experiment_CL_vs_WH_2',
    variants: {
      control: 'RAF_experiment_CL_vs_WH_2_control',
      v1: 'RAF_experiment_CL_vs_WH_2_v1',
      v2: 'RAF_experiment_CL_vs_WH_2_v2',
    },
  },
  RAF_BANNER_REDESIGN: {
    name: 'RAF_experiment_banner_redesign',
    variants: {
      control: 'RAF_experiment_banner_redesign_control',
      v1: 'RAF_experiment_banner_redesign_v1',
      v2: 'RAF_experiment_banner_redesign_v2',
    },
  },
  RAF_SECTION_NAMING: {
    name: 'RAF_experiment_naming',
    variants: {
      control: 'RAF_experiment_naming_control',
      v1: 'RAF_experiment_naming_v1',
    },
  },
  GERMANY_SIZES: {
    name: 'germany_sizes',
    variants: {
      control: 'germany_sizes_control',
      v1: 'germany_sizes_v1',
    },
  },
  PICKUP_BADGE_FR: {
    name: 'Pickup_badge_experiment_FR',
    variants: {
      control: 'Pickup_badge_experiment_control',
      v1: 'Pickup_badge_experiment_FR_v1',
    },
  },
  PICKUP_FIX: {
    name: 'pickup_fix',
    variants: {
      control: 'pickup_fix_control',
      v1: 'pickup_fix_v1',
    },
  },
  PHOTOS_AVOIDANCE: {
    name: 'photos_avoidance',
    variants: {
      control: 'photos_avoidance_control',
      v1: 'photos_avoidance_v1',
    },
  },
  STICKY_CTA: {
    name: 'sticky_cta',
    variants: {
      control: 'sticky_cta_control',
      v1: 'sticky_cta_v1',
    },
  },
  GRW_OSR: {
    name: 'grw_osr',
    variants: {
      control: 'grw_osr_control',
      v1: 'grw_osr_v1',
    },
  },
  SUBSCRIPTION_ADOPTION_2: {
    name: 'grw_subscription_adoption_2',
    variants: {
      control: 'grw_subscription_adoption_2_control',
      v1: 'grw_subscription_adoption_2_v1',
      v2: 'grw_subscription_adoption_2_v2',
    },
  },
};

export {experiments};
