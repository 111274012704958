import PropTypes from 'prop-types';
import React from 'react';
import {useIntl} from 'react-intl';

import {useApp} from '@app';
import {useOverallInfo} from '@components/_context';
import {disableScroll} from '@helpers';
import {LookAndLike as LookAndLikeBase} from '@lookiero/look-and-like';

export const LookAndLike = ({view, ...others}) => {
  const {setLoading} = useApp();

  const {formatMessage} = useIntl();
  const {id: customer} = useOverallInfo();

  return (
    <LookAndLikeBase
      {...others}
      view={view}
      session={customer}
      translate={formatMessage}
      onBlur={() => disableScroll(false)}
      onError={() => setLoading(false)}
      onFocus={() => disableScroll(true)}
      onReady={() => setLoading(false)}
    />
  );
};

LookAndLike.propTypes = {
  view: PropTypes.string,
};
