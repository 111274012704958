import {LocationRepository} from '@domain/model/Site/LocationRepository';

import {getLocale} from './getLocale';
import {getStore} from './getStore';
import {getHostname} from './getHostname';
import {getSiteByCountry} from './getSiteByCountry';
import {setStore} from './setStore';

const LocationRepositoryImpl: LocationRepository = {
  getStore,
  getLocale,
  getHostname,
  setStore,
  getSiteByCountry,
};

export {LocationRepositoryImpl};
