import React from 'react';
import {useDevice, View} from '@lookiero/aurora';
import {Logo} from 'assets/Logo';
import {useNavigationBar} from '@components/hooks/useNavigationBar';
import {isTinyScreen} from '@app/helpers/isTinyScreen';
import {style} from './LogoMobile.style';

export const LogoMobile: React.FC = () => {
  const {platform, screen} = useDevice();
  const isNavigationBarEnabled = useNavigationBar();

  const isWebMobile = platform.web && (screen.S || screen.M);
  const isLogoEnabled = isWebMobile && isNavigationBarEnabled;

  return isLogoEnabled ? (
    <View style={[style.logo, isTinyScreen(screen) ? style.logoTiny : style.logoMedium]}>
      <Logo />
    </View>
  ) : null;
};
