import PropTypes from 'prop-types';
import React from 'react';
import {
  FLEX_DIRECTION,
  LAYOUT,
  //Components
  Text,
  View,
  Button,
  AccordionContainer,
  Accordion,
} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT, COURIER} from '../ModalReturnInstructions.definition';
import {isCourierReturnEnabled} from '../ModalReturnInstructions.controller';
import {useIntl} from 'react-intl';
import {openExternalLink} from '@router/helpers';

export const ModalReturnInstructionsGLS = ({country, links, orderReference}) => {
  const {formatMessage} = useIntl();

  return (
    <>
      <View marginBottom={LAYOUT.S} style={style.steps}>
        <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={LAYOUT.XS}>
          <Text style={style.step}>1.</Text>
          <Text style={style.step}>
            {formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE, {
              courierGB: COURIER.DPD,
            })}
          </Text>
        </View>
        <View flexDirection={FLEX_DIRECTION.ROW} key={1}>
          <Text style={style.step}>2.</Text>
          <Text style={style.step}>
            {formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO, {
              courierGB: COURIER.DPD,
            })}
          </Text>
        </View>
      </View>
      <AccordionContainer marginBottom={LAYOUT.S} wide>
        {isCourierReturnEnabled(COURIER.GLS, country) ? (
          <Accordion key={0} title={formatMessage(TEXT.ACCORDION.SEND_PICKUP.GLS.TITLE)}>
            <View marginVertical={LAYOUT.S}>
              <Text as="p" key={0}>
                {formatMessage(TEXT.ACCORDION.SEND_PICKUP.GLS.TEXT)}
              </Text>
            </View>
            <Button onPress={() => openExternalLink(links.searchPickupPoint)} style={style.buttonCouriers}>
              {formatMessage(TEXT.ACCORDION.SEND_PICKUP.GLS.BUTTON)}
            </Button>
          </Accordion>
        ) : (
          <></>
        )}
        <Accordion key={1} title={formatMessage(TEXT.ACCORDION.SEND_HOME.GLS.TITLE)}>
          <View marginVertical={LAYOUT.S}>
            <Text as="p" key={0}>
              {formatMessage(TEXT.ACCORDION.SEND_HOME.GLS.TEXT)}
            </Text>
          </View>
          <Button onPress={() => openExternalLink(links.requestPickup)} style={style.buttonCouriers}>
            {formatMessage(TEXT.ACCORDION.SEND_HOME.GLS.BUTTON)}
          </Button>
        </Accordion>
      </AccordionContainer>
    </>
  );
};

ModalReturnInstructionsGLS.propTypes = {
  country: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  orderReference: PropTypes.string.isRequired,
};
