import {Notification, useStack} from '@lookiero/aurora';
import {iOSInAppBrowserVideoAd} from 'ui/helpers';

import {style} from './ToastContext.styles';

const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

const getNotificationDefaults = () => ({
  motion: 'top',
  style: style.container,
  timeoutClose: 10000,
});

const getInAppNotificationDefaults = () => ({
  ...getNotificationDefaults(),
  style: [style.container, style.inAppContainer],
});

const getNotificationProps = () =>
  iOSInAppBrowserVideoAd ? getInAppNotificationDefaults() : getNotificationDefaults();
const generateUid = () => new Date().getTime().toString();

const useToast = uid => {
  const Stack = useStack();

  const getUid = () => uid || generateUid();

  return {
    show: (Component, props) => {
      Stack.show(getUid(), Component, props);
    },
    showError: ({message, settings = {}}) => {
      Stack.alert(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    showSuccess: ({message, settings = {}}) => {
      Stack.success(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    showWarning: ({message, settings = {}}) => {
      Stack.alert(getUid(), Notification, {...getNotificationProps(), ...settings, text: message});
    },
    hide: id => {
      Stack.hide(id);
    },
  };
};

export {getNotificationDefaults, useToast, TOAST_TYPES, generateUid};
