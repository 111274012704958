import {addEventListener} from '@react-native-community/netinfo';
import {useEffect, useState} from 'react';

export const INTERNET_OKAY = 'internet_okay';
export const NO_INTERNET_VIEW_LOADED = 'no_internet_view_loaded';
export const NO_INTERNET_NOTHING_LOADED = 'no_internet_nothing_loaded';

export const useInternetStatus = ({loading}) => {
  const [internetStatus, setInternetStatus] = useState(INTERNET_OKAY);

  useEffect(() => {
    return addEventListener(({isInternetReachable}) => {
      if (isInternetReachable === null || isInternetReachable) {
        setInternetStatus(INTERNET_OKAY);
        return;
      }

      if (internetStatus !== INTERNET_OKAY) {
        return;
      }

      setInternetStatus(loading ? NO_INTERNET_NOTHING_LOADED : NO_INTERNET_VIEW_LOADED);
    });
  }, [internetStatus, loading]);

  return internetStatus;
};
