import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  panel: {
    paddingLeft: '$spaceS',
    paddingRight: '$spaceS',
  },
  panelLarge: {
    paddingRight: '$spaceM',
  },
  navBarButtonLarge: {
    marginLeft: '$spaceL',
  },
});
