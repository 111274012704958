import {SiteDomain} from '@domain/model/Site/Site';
import {Locale} from '@domain/model/Site/Locale';

import {COUNTRY, LANGUAGE} from './Site.definition';
import {getStore} from './getStore';

const getLocale = async (): Promise<Locale> => {
  const site: SiteDomain = await getStore();
  return {language: LANGUAGE[site], country: COUNTRY[site]};
};

export {getLocale, LANGUAGE, COUNTRY};
