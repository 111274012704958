import {StyleSheet} from 'react-native';
import {iOSInAppBrowserBannerHeight} from 'ui/helpers';

const style = StyleSheet.create({
  inAppBrowserBanner: {
    alignItems: 'center',
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    height: iOSInAppBrowserBannerHeight,
    justifyContent: 'center',
    top: 0,
    width: '100%',
    zIndex: 3,
  },
  bannerText: {
    paddingTop: 0,
  },
});

export {style};
