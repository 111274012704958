import React, {FC, ReactNode} from 'react';
import {Platform, View, SafeAreaView} from 'react-native';
import {useMatch} from 'react-router-native';
import {useKeyboard, usePortal} from '@lookiero/aurora';

import {InAppBrowserBanner, Panel} from '@components/atoms';
import {iOSInAppBrowserVideoAd} from 'ui/helpers';
import {TabBar} from '@router/bridges/TabBar';
import {useApp} from '@app';
import {Path} from '@router/Routes.config.definition';
import {ShopRouteTypes, useShop} from '@components/_context';

import {Header} from './Header';
import {style} from './Navigation.style';

interface NavigationProps {
  readonly children: ReactNode;
}
const Navigation: FC<NavigationProps> = ({children}) => {
  const {authorization} = useApp();
  const {busy} = usePortal();
  const {isKeyboardOpen} = useKeyboard();

  const isIntroRoute = Boolean(useMatch(Path.INTRO));

  const {shopRouteType} = useShop();
  const isShopRoute = shopRouteType !== ShopRouteTypes.OUTTER;

  const tabBarHidden = shopRouteType === ShopRouteTypes.SECONDARY || isKeyboardOpen || busy;

  return (
    <>
      {iOSInAppBrowserVideoAd && <InAppBrowserBanner />}

      <SafeAreaView style={style.safeAreaView}>
        <Header hasSectionBar={isShopRoute} hideBurgerBar={isIntroRoute || isShopRoute} />
      </SafeAreaView>

      <Panel />

      <View style={style.children}>{children}</View>

      {Platform.OS !== 'web' && authorization && <TabBar hidden={tabBarHidden} />}
    </>
  );
};

export {Navigation};
