import StyleSheet from 'react-native-extended-stylesheet';

const IMAGE_SIZE = 24;

export const styles = StyleSheet.create({
  buttonDesktop: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    height: '100%',
    overflow: 'visible',
    justifyContent: 'center',
  },
  iconDesktop: {
    marginRight: '$spaceS',
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    top: 0,
    left: 0,
  },
});
