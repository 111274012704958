import React, {FC, ReactNode, useEffect} from 'react';
import {useLocation} from 'react-router-native';

import {Scroller, useScroll} from './components';
import {useApp} from '@app';
import {useNavigationBar} from '@components/hooks/useNavigationBar';

import {style} from './App.style';
import {ShopRouteTypes, useShop} from '@components/_context';

interface AppViewProps {
  readonly children: ReactNode;
}

export const AppView: FC<AppViewProps> = ({children}) => {
  const {authorization, setLoading, togglePanelVisibility} = useApp();
  const {scrollTo} = useScroll();
  const {pathname} = useLocation();

  const isNavigationBarEnabled = useNavigationBar();

  const {shopRouteType} = useShop();
  const notShopRoute = shopRouteType === ShopRouteTypes.OUTTER;

  useEffect(() => {
    scrollTo(0);
    setLoading(true);
    togglePanelVisibility(false);
  }, [pathname, scrollTo, setLoading, togglePanelVisibility]);

  return (
    <Scroller style={isNavigationBarEnabled && notShopRoute && style.scroller}>
      {authorization !== undefined && children}
    </Scroller>
  );
};
