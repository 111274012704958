import {HOSTNAME, SITE} from '@infra/domain/model/Site/Site.definition';

export {HOSTNAME, SITE};
export type SiteDomain = typeof SITE[keyof typeof SITE];

export interface DeliveryPoint {
  name: string;
  selected: boolean;
  localCourier: string;
}

export interface Delivery {
  home: DeliveryPoint;
  pickup: DeliveryPoint;
}

interface SiteBase {
  site: string;
  store: number;
  languages: string[];
  dateFormat: string;
  declinedCodes: string[];
  defaultLanguage: string;
  countryPhoneCode: string;
  delivery: Delivery;
  measureSystem: string;
  currency: string;
  paymentMethods: string[];
  servicePromo?: string;
  giftcardAvailable: boolean;
  psFee: number;
}

export interface SiteDTO extends SiteBase {
  postCodeRegex: string;
  phoneRegex: string;
}

export interface Site extends SiteBase {
  postCodeRegex: RegExp;
  phoneRegex: RegExp;
}
