import React, {ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle} from 'react';
import {NativeScrollEvent, NativeSyntheticEvent, ScrollView, View} from 'react-native';

import {InAppBrowserBanner} from '@components/atoms';

interface IOSInAppBrowserVideoAdScrollViewProps {
  readonly children: ReactNode;
  readonly onScroll?: ((event: NativeSyntheticEvent<NativeScrollEvent>) => void) | undefined;
  readonly scrollEnabled?: boolean;
}
// eslint-disable-next-line react/display-name
const IOSInAppBrowserVideoAdScrollView = forwardRef<ScrollView, IOSInAppBrowserVideoAdScrollViewProps>(
  ({children, onScroll, scrollEnabled}, ref) => {
    const handleOnScroll = useCallback(
      () =>
        onScroll &&
        onScroll({
          nativeEvent: {
            contentOffset: {
              x: window.scrollX,
              y: window.scrollY,
            },
            contentSize: {
              height: window.document.body.scrollHeight,
              width: window.document.body.scrollWidth,
            },
            layoutMeasurement: {
              height: window.innerHeight,
              width: window.innerWidth,
            },
          },
        } as NativeSyntheticEvent<NativeScrollEvent>),
      [onScroll],
    );

    useEffect(() => {
      window.addEventListener('scroll', handleOnScroll, {passive: true});

      return () => {
        window.removeEventListener('scroll', handleOnScroll);
      };
    }, [handleOnScroll]);

    useImperativeHandle(
      ref,
      () =>
        ({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          scrollTo({y, x, animated}) {
            window.scrollTo({
              left: x,
              top: y,
              behavior: animated ? 'smooth' : 'auto',
            });
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          scrollToEnd({animated}) {
            window.scrollTo({
              left: 0,
              top: document.body.scrollHeight,
              behavior: animated ? 'smooth' : 'auto',
            });
          },
        } as ScrollView),
    );

    return (
      <View style={[!scrollEnabled && {overflow: 'hidden', height: '100%'}]}>
        <InAppBrowserBanner />

        {children}
      </View>
    );
  },
);

export {IOSInAppBrowserVideoAdScrollView};
