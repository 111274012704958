import {Platform} from 'react-native';
import {generatePath} from 'react-router-native';

import {Country, COUNTRY} from '@domain/model/Site/Locale';
import {SITE} from '@domain/model/Site/Site';
import {getSiteByCountry} from '@infra/domain/model/Site/getSiteByCountry';
import {Path} from '@router/Routes.config.definition';
import {LINKS} from './Panel.definition';

export interface Link {
  readonly path: string;
  readonly id?: string;
  readonly testid?: string;
}
export interface Route {
  readonly path: Path;
  readonly id?: string;
  readonly testid?: string;
}

const COUNTRIES_WITHOUT_BLOG: Country[] = [COUNTRY.AT, COUNTRY.BE, COUNTRY.SE];

const giftCardFilter = (isAvailable: boolean) => (link: Link) => link.path !== '/giftcard' || isAvailable;

const blogFilter = (country?: Country) => (link: Link) =>
  link.path !== '/blog/' || !(country && COUNTRIES_WITHOUT_BLOG.includes(country));

const FAQ_LOCALE = {
  [SITE.AT]: 'de-at',
  [SITE.BE]: 'fr-be',
  [SITE.DE]: 'de',
  [SITE.ES]: 'es',
  [SITE.FR]: 'fr',
  [SITE.IT]: 'it',
  [SITE.NL]: 'nl',
  [SITE.PT]: 'pt',
  [SITE.UK]: 'en-gb',
  [SITE.SE]: 'sv',
};

const faqsMapper = (country?: Country) => (link: Link) => {
  const isApplicable = link.path === 'https://support.lookiero.com/hc/:locale' && Platform.OS === 'web';
  if (!isApplicable) {
    return link;
  }
  if (!country) {
    return {...link, path: generatePath(link.path, {locale: ''})};
  }
  const site = getSiteByCountry(country);
  const locale = FAQ_LOCALE[site];
  const path = !locale ? link.path.replace(':locale', country) : generatePath(link.path, {locale});

  return {...link, path};
};

const getLinksToShow = (giftCardAvailable: boolean, country?: Country): Link[] =>
  LINKS.filter(giftCardFilter(giftCardAvailable))
    .filter(blogFilter(country))
    .map(faqsMapper(country));

export {getLinksToShow};
