import {ALIGN, DISPLAY, FLEX_DIRECTION, LAYOUT} from '@lookiero/aurora';

const CONTENT_PROPS = {
  size: {M: '2/3', L: '1/3'},
};

const CONTENT_PROPS_TINY = {
  size: {M: '2/3', L: '1/4'},
};

const TITLE_PROPS = {
  alignItems: ALIGN.CENTER,
  align: ALIGN.CENTER as const,
  display: DISPLAY.FLEX,
  flexDirection: FLEX_DIRECTION.COLUMN,
  size: {L: '1/2'},
  marginBottom: LAYOUT.M,
};

const APP_STATES = {STRIPE: 'stripe'};

export {APP_STATES, CONTENT_PROPS, CONTENT_PROPS_TINY, TITLE_PROPS};
