import React, {FC, useCallback, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Portal, useDevice, useKeyboard} from '@lookiero/aurora';

import {eventManager} from '@application/Event';
import {useApp} from '@app';
import {useWebview} from '@app/components/Webview';
import {useSettings, useShop} from '@components/_context';
import {useLocale} from '@components/hooks';
import {PageEventView} from '@domain/model/Event/domain/model/UiEvents';
import {UiEvent} from '@domain/model/Event';

import {getLinksToShow} from './Panel.controller';
import {useRafSectionNaming} from '@mendel/_experiments/useRafSectionNaming';
import {IsolatedPanel} from './IsolatedPanel';
import {useMountablePortal} from '@components/hooks';

export const Panel: FC = () => {
  const {isPanelVisible: isVisible} = useApp();

  const {isMountedAndVisible, showComponent: showPanel} = useMountablePortal({isVisible});

  return isMountedAndVisible ? (
    <Portal id="panel">
      <PanelWithoutPortal isVisible={showPanel} />
    </Portal>
  ) : null;
};

Panel.displayName = 'Panel';

const PanelWithoutPortal: FC<{isVisible: boolean}> = ({isVisible}) => {
  const {authorization, togglePanelVisibility} = useApp();
  const {platform} = useDevice();
  const {formatMessage} = useIntl();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const {country} = useLocale();
  const {giftcardAvailable = false} = useSettings();
  const {openLink} = useWebview();
  const {isKeyboardOpen, closeKeyboard} = useKeyboard();
  const {canAccess: userCanAccessShop} = useShop();

  const {enabled: isRafExpEnabled, credit: referralCredit} = useRafSectionNaming();

  const filteredLinks = useMemo(() => getLinksToShow(giftcardAvailable, country), [giftcardAvailable, country]);

  useEffect(() => {
    if (platform.native && isVisible && isKeyboardOpen) {
      closeKeyboard();
    }
  }, [closeKeyboard, isKeyboardOpen, isVisible, platform.native]);

  useEffect(() => {
    if (isVisible) {
      eventManager.emit(UiEvent.PAGE_LOAD, {view: PageEventView.OPEN_MENU});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnPanelClose = useCallback(() => togglePanelVisibility(false), [togglePanelVisibility]);

  return (
    <IsolatedPanel
      visible={isVisible}
      authorization={authorization}
      isRafExpEnabled={isRafExpEnabled}
      referralCredit={referralCredit}
      userCanAccessShop={userCanAccessShop}
      filteredLinks={filteredLinks}
      onClose={handleOnPanelClose}
      openLink={openLink}
      formatMessage={formatMessage}
    />
  );
};

PanelWithoutPortal.displayName = 'PanelWithoutPortal';
