import React from 'react';
import PropTypes from 'prop-types';
import {Box, Icon, Divider, Text, Touchable, ALIGN, DISPLAY, FLEX_DIRECTION, LAYOUT} from '@lookiero/aurora';

const ShareListItem = ({children, isLast, iconName, onPress, 'data-testid': testid}) => {
  return (
    <Touchable as="li" onPress={onPress} style={{cursor: 'pointer'}} wide testID={testid}>
      <Box
        flexDirection={FLEX_DIRECTION.ROW}
        alignItems={ALIGN.CENTER}
        display={DISPLAY.FLEX}
        justifyContent={ALIGN.BETWEEN}
        paddingVertical={LAYOUT.XS}
      >
        <Text action level={3} upperCase>
          {children}
        </Text>
        <Icon name={iconName} />
      </Box>
      {!isLast && <Divider />}
    </Touchable>
  );
};

ShareListItem.propTypes = {
  children: PropTypes.string.isRequired,
  isLast: PropTypes.bool,
  iconName: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
};

export {ShareListItem};
