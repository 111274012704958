import {ApiReferralRepository} from '@raf/core/infrastructure/domain/ApiReferralRepository';

import {GetReferral} from './GetReferral';
import {GetReferralForShare} from './GetReferralForShare';
import {GetReferralInfoByCode} from './GetReferralInfoByCode';

export const ReferralApplication = {
  getReferral: GetReferral(ApiReferralRepository),
  getReferralForShare: GetReferralForShare(ApiReferralRepository),
  getReferralInfoByCode: GetReferralInfoByCode(ApiReferralRepository),
};
