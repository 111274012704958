import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonDesktop: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonMobile: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    height: '100%',
    overflow: 'visible',
    justifyContent: 'center',
  },
  skeleton: {
    color: '$colorGrayscaleM',
  },
  iconDesktop: {
    width: 24,
    height: 24,
    marginRight: '$spaceS',
  },
  iconMobile: {
    width: 24,
    height: 22,
    marginBottom: '$spaceXXS',
  },
  textDesktop: {
    width: 42,
    height: 16,
  },
  textMobile: {
    width: 42,
    height: 12,
  },
});
