import {Path} from '@router/Routes.config.definition';
import {Link, Route} from './Panel.controller';

const LINKS: Link[] = [
  {
    path: '/blog/',
    id: 'navigation.menu.blog',
    testid: 'link-blog',
  },
  {
    path: '/giftcard',
    id: 'navigation.menu.giftCard',
    testid: 'link-giftcard',
  },
  {
    path: 'https://support.lookiero.com/hc/:locale',
    id: 'navigation.menu.help',
    testid: 'link-help',
  },
  {
    path: '/terms-and-conds',
    id: 'navigation.menu.tc',
    testid: 'link-terms',
  },
];

enum MenuItems {
  HOME = 'HOME',
  SHOP = 'SHOP',
  SHOP_ORDERS = 'SHOP_ORDERS',
  REFERRALS = 'REFERRALS',
  STYLE = 'STYLE',
}

const MAIN_MENU_ITEMS: Record<MenuItems, Route> = {
  [MenuItems.HOME]: {
    path: Path.HOME,
    id: 'navigation.menu.myLookiero',
    testid: 'link-home',
  },
  [MenuItems.SHOP]: {
    path: Path.SHOP_HOME,
    id: 'shop.menuaccess.main',
    testid: 'link-shop',
  },
  [MenuItems.SHOP_ORDERS]: {
    path: Path.SHOP_ORDERS,
    id: 'shop.menu.item.my_orders',
    testid: 'link-shop-orders',
  },
  [MenuItems.REFERRALS]: {
    path: Path.REFERRALS,
    id: 'navigation.menu.referrals',
    testid: 'link-referrals',
  },
  [MenuItems.STYLE]: {
    path: Path.STYLE_HOME,
    id: 'navigation.menu.style',
    testid: 'link-style',
  },
};

const SHOP_ROUTES: MenuItems[] = [MenuItems.SHOP, MenuItems.SHOP_ORDERS];

const TEXT = {
  ACCOUNT_CTA: {id: 'navigation.menu.account'},
  LOGIN: {id: 'navigation.menu.question.text'},
  LOGIN_CTA: {id: 'navigation.menu.question.cta'},
  QUIZ_CTA: {id: 'navigation.start.cta'},
  RAF_EXP: {id: 'navigation.bar.raf_exp'},
};

export {LINKS, MAIN_MENU_ITEMS, SHOP_ROUTES, TEXT};
