import React, {FC} from 'react';
import {Text, View, Icon, ALIGN, COLOR, LAYOUT} from '@lookiero/aurora';
import {useIntl} from 'react-intl';

import {style} from './InAppBrowserBanner.styles';
import {TEXT} from './InAppBrowserBanner.definition';

const InAppBrowserBanner: FC = () => {
  const {formatMessage} = useIntl();

  return (
    <View testID="banner" style={style.inAppBrowserBanner}>
      <Icon name="truck" color={COLOR.BASE} marginRight={LAYOUT.XXS} />
      <Text align={ALIGN.CENTER} color={COLOR.BASE} detail level={2} style={style.bannerText}>
        {formatMessage(TEXT.BANNER_TEXT)}
      </Text>
    </View>
  );
};

export {InAppBrowserBanner};
