import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {ScrollView} from '@lookiero/aurora';

import {
  retrieveLinks,
  retrieveShipmentByOrderReference,
  retrieveShipmentByServiceId,
} from './ModalReturnInstructions.controller';
import {useLocale} from '@components/hooks';
import {useIntl} from 'react-intl';
import {useToast} from '@components/_context';
import {COURIER, TEXT} from './ModalReturnInstructions.definition';
import {ModalReturnInstructionsSEUR} from './courier/ModalReturnInstructions.SEUR';
import {ModalReturnInstructionsGLS} from './courier/ModalReturnInstructions.GLS';
import {ModalReturnInstructionsDPD} from './courier/ModalReturnInstructions.DPD';
import {ModalReturnInstructionsBRT} from './courier/ModalReturnInstructions.BRT';
import {ModalReturnInstructionsEVRI} from './courier/ModalReturnInstructions.EVRI';
import {ModalReturnInstructionsMR} from './courier/ModalReturnInstructions.MR';
import {ModalReturnInstructionsINPOST} from './courier/ModalReturnInstructions.INPOST';
import {ModalReturnInstructionsUPS} from './courier/ModalReturnInstructions.UPS';
import {useDevice} from '@lookiero/aurora';
import ModalReturnInstructionsVINTED from './courier/ModalReturnInstructions.VINTED';
import {MountableModal} from '@components/molecules';

const BIG_SCREEN_HEIGHT = 1024;
const SMALL_SCREEN_HEIGHT_PERCENTAGE = 0.9;
const BIG_SCREEN_HEIGHT_PERCENTAGE = 0.7;

export const ModalReturnInstructions = ({orderReference, serviceId, onClose, ...others}) => {
  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);
  const [shipment, setShipment] = useState(undefined);
  const [screenHeight, setScreenHeight] = useState(0);
  const toast = useToast();
  const {country} = useLocale();

  const {formatMessage} = useIntl();

  const {screen} = useDevice();

  const isMobileDevice = screen.L === false;

  useEffect(() => {
    const percentage =
      screen.height >= BIG_SCREEN_HEIGHT ? BIG_SCREEN_HEIGHT_PERCENTAGE : SMALL_SCREEN_HEIGHT_PERCENTAGE;
    setScreenHeight(screen.height * percentage);
  }, [screen.height, setScreenHeight]);

  useEffect(() => {
    (async () => {
      try {
        const shipment =
          orderReference !== undefined
            ? await retrieveShipmentByOrderReference(orderReference)
            : await retrieveShipmentByServiceId(serviceId);
        setShipment(shipment);
      } catch (e) {
        setErrorLoading(true);
      }
      setLoading(false);
    })();
  }, [orderReference, serviceId]);

  function getModal(courier) {
    switch (courier) {
      case COURIER.MR:
        return (
          <ModalReturnInstructionsMR
            country={country}
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.IN_POST:
        return (
          <ModalReturnInstructionsINPOST
            country={country}
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.SEUR:
        return (
          <ModalReturnInstructionsSEUR
            country={country}
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.GLS:
        return (
          <ModalReturnInstructionsGLS
            country={country}
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.DPD:
        return (
          <ModalReturnInstructionsDPD
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.BRT:
        return (
          <ModalReturnInstructionsBRT
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.EVRI:
        return (
          <ModalReturnInstructionsEVRI
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.UPS:
        return (
          <ModalReturnInstructionsUPS
            shipmentType={shipment.send.type}
            links={retrieveLinks(shipment)}
            orderReference={orderReference}
          />
        );
      case COURIER.VINTED_GO:
        return <ModalReturnInstructionsVINTED onClose={onClose} />;
    }
  }

  function renderErrorLoading() {
    if (errorLoading) {
      toast.showError({
        message: formatMessage(TEXT.INSTRUCTION_ERROR),
      });
      setErrorLoading(false);
    }
  }

  return (
    <>
      {!loading && shipment && (
        <MountableModal
          {...others}
          portalID="modal-return-instructions-new"
          scrollable={true}
          onClose={onClose}
          fullHeight={isMobileDevice}
          contentStyle={isMobileDevice ? undefined : {height: screenHeight}}
          title={formatMessage(orderReference !== undefined ? TEXT.TITLE : TEXT.SHOP_TITLE)}
          testID="modal-return-instructions"
        >
          <ScrollView style={{flex: 1}}>{getModal(shipment.return.courier)}</ScrollView>
        </MountableModal>
      )}
      {!loading && !shipment && renderErrorLoading()}
    </>
  );
};

ModalReturnInstructions.propTypes = {
  orderReference: PropTypes.string,
  serviceId: PropTypes.string,
  onClose: PropTypes.func,
};
