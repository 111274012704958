import React from 'react';
import {POSITION, ALIGN, COLOR, ButtonIcon, Text, View} from '@lookiero/aurora';

import {style} from './SubNavigation.style';

export type SubNavigationProps = {
  children?: React.ReactNode;
  title?: string;
  onBack?: () => void;
};

const BackButton: React.FC<{onPress: () => void}> = ({onPress}) => (
  <ButtonIcon
    style={style.backButton}
    name="arrow-left"
    testID="navbar-back-button"
    nativeID="HeaderBack"
    onPress={onPress}
  />
);

const Title: React.FC<{children: string}> = ({children}) => (
  <Text action align={ALIGN.CENTER} color={COLOR.CONTENT} position={POSITION.ABSOLUTE} style={style.title} upperCase>
    {children}
  </Text>
);

export const SubNavigation: React.FC<SubNavigationProps> = ({onBack, title, children}) => {
  return (
    <View style={style.container}>
      {onBack && <BackButton onPress={onBack} />}
      {title ? <Title>{title}</Title> : children}
    </View>
  );
};
