import React, {
  createContext,
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {ScrollView as NativeScrollView, StyleProp, ViewStyle} from 'react-native';
import {SIZE, ScrollView, usePortal, View} from '@lookiero/aurora';
import {ScrollPayload} from '@lookiero/aurora/build/components/primitives/ScrollView/ScrollView';
import {iOSInAppBrowserVideoAd} from 'ui/helpers';

interface Subscriber {
  (event: ScrollPayload): void;
}

type Subscribers = Record<string, Subscriber>;

interface SubscribeFunction {
  (id: string, callback: Subscriber): void;
}

interface UnsubscribeFunction {
  (id: string): void;
}

interface OnScrollFunction {
  (event: ScrollPayload): void;
}

interface ScrollToFunction {
  (scrollY: number): void;
}

export interface ScrollContextShape {
  readonly scrollRef: RefObject<NativeScrollView>;
  readonly subscribe: SubscribeFunction;
  readonly unsubscribe: UnsubscribeFunction;
  readonly onScroll: OnScrollFunction;
  readonly scrollTo: ScrollToFunction;
}

const ScrollContext = createContext<ScrollContextShape>({} as ScrollContextShape);

interface ScrollProviderProps {
  readonly children: ReactNode;
}
const ScrollProvider: FC<ScrollProviderProps> = ({children}) => {
  const scrollRef = useRef<NativeScrollView>(null);
  const subscribers = useRef<Subscribers>({});

  const subscribe: SubscribeFunction = useCallback((id, callback) => {
    subscribers.current = {
      ...subscribers.current,
      [id]: callback,
    };
  }, []);

  const unsubscribe: UnsubscribeFunction = useCallback(
    id => Object.fromEntries(Object.entries(subscribers.current).filter(([subscriberId]) => subscriberId !== id)),
    [],
  );

  const onScroll: OnScrollFunction = useCallback(
    event => Object.values(subscribers.current).forEach(callback => callback(event)),
    [],
  );

  const scrollTo: ScrollToFunction = useCallback(scrollY => {
    if (iOSInAppBrowserVideoAd) {
      window.scrollTo({top: scrollY, behavior: scrollY === 0 ? 'auto' : 'smooth'});
    } else if (scrollRef.current) {
      scrollRef.current.scrollTo({y: scrollY, animated: Boolean(scrollY)});
    }
  }, []);

  const value = useMemo(
    () => ({
      scrollRef,
      subscribe,
      unsubscribe,
      onScroll,
      scrollTo,
    }),
    [onScroll, scrollTo, subscribe, unsubscribe],
  );

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

const useScrollContext = (): ScrollContextShape | never => {
  const context = useContext(ScrollContext);

  if (!context.scrollRef) {
    throw new Error(
      'Your are trying to use the useScrollContext hook without wrapping your app with the <ScrollProvider>.',
    );
  }

  return context;
};

interface UseScrollReturn {
  readonly subscribe: SubscribeFunction;
  readonly unsubscribe: UnsubscribeFunction;
  readonly scrollTo: ScrollToFunction;
}
const useScroll = (): UseScrollReturn => {
  const {subscribe, unsubscribe, scrollTo} = useScrollContext();

  return {
    scrollTo,
    subscribe,
    unsubscribe,
  };
};

export interface ScrollerProps {
  readonly children: React.ReactNode;
  readonly style?: StyleProp<ViewStyle>;
}

const Scroller: FC<ScrollerProps> = ({children, style: customStyle}) => {
  const {scrollRef, onScroll} = useScrollContext();
  const {busy} = usePortal();

  const handleOnScroll = useCallback(
    () =>
      onScroll({
        X: window.scrollX,
        Y: window.scrollY,
        percentX: 0,
        percentY: 0,
      }),
    [onScroll],
  );

  useEffect(() => {
    if (iOSInAppBrowserVideoAd) {
      window.addEventListener('scroll', handleOnScroll, {passive: true});

      return () => {
        window.removeEventListener('scroll', handleOnScroll);
      };
    }
  }, [handleOnScroll]);

  if (iOSInAppBrowserVideoAd) {
    return <View style={customStyle}>{children}</View>;
  }

  return (
    <ScrollView
      flex={SIZE.XS}
      keyboardShouldPersistTaps="handled"
      ref={scrollRef}
      scrollEnabled={!busy}
      testID="scroll-view"
      onScroll={onScroll}
      scrollEventThrottle={16}
      style={customStyle}
    >
      {children}
    </ScrollView>
  );
};

export {Scroller, ScrollProvider, useScroll};
