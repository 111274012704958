import React from 'react';
import {Motion, useDevice, View} from '@lookiero/aurora';
import {useApp} from '@app';
import {useUnstableNestedScrollInView} from '@app/useUnstableNestedScrollInView';
import {iOSInAppBrowserBannerHeight, iOSInAppBrowserVideoAd} from 'ui/helpers';

import {useNavScroll} from './useNavScroll';
import {style} from './NavWrapper.style';

type NavigationBarProps = {
  hasSectionBar?: boolean;
  children: React.ReactNode;
};

const MOBILE_HEADER_HEIGHT = 55;
const DESKTOP_HEADER_HEIGHT = 48;
const HEADER_LOGO_OFFSET_TO_HIDE_COMPLETELY = 7;

const getHeightToHideHeader = (isMobile: boolean, hasBanner: boolean): number => {
  if (iOSInAppBrowserVideoAd) {
    return iOSInAppBrowserBannerHeight;
  }
  if (isMobile) {
    return MOBILE_HEADER_HEIGHT;
  }
  if (hasBanner) {
    return DESKTOP_HEADER_HEIGHT;
  }
  return DESKTOP_HEADER_HEIGHT + HEADER_LOGO_OFFSET_TO_HIDE_COMPLETELY;
};

export const NavWrapper: React.FC<NavigationBarProps> = ({hasSectionBar = false, children}) => {
  const {screen} = useDevice();
  const {navBarVisible} = useNavScroll();

  const {header: {banner = undefined} = {}} = useApp();

  //**** WORKAROUND TO AVOID A BLANK SPACE ON TOP WHEN HIDING NEW NAVIGATION BAR DUE TO SHOP NESTED SCROLLS
  const {UNSTABLE_NESTED_SCROLL_IN_VIEW} = useUnstableNestedScrollInView();
  //**************************

  const heightToHideHeader = getHeightToHideHeader(screen.S || screen.M, banner);

  return (
    <View style={[style.container, !hasSectionBar && style.sticky]}>
      <Motion
        value={{
          marginTop: UNSTABLE_NESTED_SCROLL_IN_VIEW && !navBarVisible ? -heightToHideHeader : 0,
          translateY: !navBarVisible ? -heightToHideHeader : 0,
        }}
        duration={250}
      >
        {children}
      </Motion>
    </View>
  );
};
