import React from 'react';
import {ViewStyle} from 'react-native';
import {Skeleton as AuroraSkeleton, useDevice, View} from '@lookiero/aurora';

import {styles} from './Skeleton.styles';

export const Skeleton: React.FC<{style?: ViewStyle}> = ({style}) => {
  const {screen} = useDevice();

  const buttonStyle = screen.L ? styles.buttonDesktop : styles.buttonMobile;
  const iconStyle = screen.L ? styles.iconDesktop : styles.iconMobile;
  const textStyle = screen.L ? styles.textDesktop : styles.textMobile;

  return (
    <View style={[styles.container, buttonStyle, style]}>
      <AuroraSkeleton style={[styles.skeleton, iconStyle]} />
      <AuroraSkeleton style={[styles.skeleton, textStyle]} />
    </View>
  );
};
