import {useScroll} from '@app/components';
import {ScrollPayload} from '@lookiero/aurora/build/components/primitives/ScrollView/ScrollView';
import {useCallback, useEffect, useRef, useState} from 'react';
import eventEmitter from 'tiny-emitter/instance';

const FORCE_SET_NAV_BAR_VISIBILITY = 'force-set-nav-bar-visibility';
const MIN_SCROLL_DELTA = 20;

const OFFSET_TO_HIDE_MENU = 35;

interface UseNavScrollReturn {
  readonly navBarVisible: boolean;
}

interface UseNavScrollFunction {
  (): UseNavScrollReturn;
}

const useNavScroll: UseNavScrollFunction = () => {
  const {subscribe, unsubscribe} = useScroll();
  const prevScrollPosition = useRef(0);

  const [navBarVisible, setNavBarVisible] = useState(true);

  const forceSetNavBarVisible = useCallback((newVal: boolean) => {
    setNavBarVisible(newVal);
  }, []);

  const handleOnScroll = useCallback((event: ScrollPayload) => {
    const currentScrollPosition = event.percentY > 100 ? prevScrollPosition.current : Math.max(0, event.Y);

    if (currentScrollPosition < OFFSET_TO_HIDE_MENU) {
      setNavBarVisible(true);
    }

    if (Math.abs(prevScrollPosition.current - currentScrollPosition) < MIN_SCROLL_DELTA) {
      return;
    }

    setNavBarVisible(prevScrollPosition.current > currentScrollPosition);

    prevScrollPosition.current = currentScrollPosition;
  }, []);

  useEffect(() => {
    subscribe('nav-scroll', handleOnScroll);
    eventEmitter.on(FORCE_SET_NAV_BAR_VISIBILITY, forceSetNavBarVisible);

    return () => {
      unsubscribe('nav-scroll');
      eventEmitter.off(FORCE_SET_NAV_BAR_VISIBILITY, forceSetNavBarVisible);
    };
  }, [forceSetNavBarVisible, handleOnScroll, subscribe, unsubscribe]);

  return {navBarVisible};
};

export {FORCE_SET_NAV_BAR_VISIBILITY, useNavScroll};
