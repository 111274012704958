import {Text} from '@lookiero/aurora';
import React from 'react';
import {useIntl} from 'react-intl';

import {MountableModal} from '@components/molecules';
import {TEXT} from './NoInternet.definition';

type ModalNoInternetProps = {isVisible: boolean};
export const ModalNoInternet = ({isVisible}: ModalNoInternetProps): JSX.Element => {
  const {formatMessage} = useIntl();

  return (
    <MountableModal isVisible={isVisible} portalID="modal-no-internet" title={formatMessage(TEXT.TITLE)}>
      <Text>{formatMessage(TEXT.TEXT)}</Text>
    </MountableModal>
  );
};
