import gql from 'graphql-tag';

const GET_REFERRAL_QUERY = gql`
  query rafInfo {
    rafInfo {
      numberOfReferredApplied
      numberOfReferredRegistered
      referralCode
      referralCredit
      referredCredit
      referreds {
        name
        amount
      }
      totalBalance
      enabled
    }
  }
`;

export {GET_REFERRAL_QUERY};
