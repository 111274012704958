import {Platform} from 'react-native';
import StyleSheet from 'react-native-extended-stylesheet';
import {iOSInAppBrowserBannerTop} from 'ui/helpers';

export const style = StyleSheet.create({
  container: {
    zIndex: '$elevationLayerS',
  },
  sticky: {
    ...Platform.select({
      web: {
        position: 'fixed',
        top: iOSInAppBrowserBannerTop,
        width: '100%',
        zIndex: 1,
      },
      native: {
        position: 'relative',
      },
    }),
  },
});
