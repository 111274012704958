import React, {FC, useCallback, useMemo} from 'react';
import {
  View,
  Panel,
  Header,
  ScrollView,
  Link as AuroraLink,
  Text,
  Box,
  Button,
  BUTTON_VARIANT,
  COLOR,
  ALIGN,
  LAYOUT,
  DISPLAY,
  SIZE,
  POSITION,
  FLEX_DIRECTION,
} from '@lookiero/aurora';
import {Logo} from 'assets/Logo';
import {style} from './IsolatedPanel.style';
import {AppVersion} from '@components/molecules/AppVersion/AppVersion';
import {DownloadButton} from '@components/molecules/AppDownloadButtons/AppDownloadButtons';
import {Platform, SafeAreaView} from 'react-native';
import {MessageDescriptor} from 'react-intl';
import {NavigateOptions, matchPath, useLocation, useNavigate} from 'react-router-native';
import {MAIN_MENU_ITEMS, SHOP_ROUTES, TEXT} from './Panel.definition';
import {Link, Route} from './Panel.controller';
import {SOURCE} from '@components/molecules/AppDownloadButtons/AppDownloadButtons.definitions';
import {InAppBrowserBanner} from '../InAppBrowserBanner';
import {iOSInAppBrowserVideoAd} from 'ui/helpers';
import {Path} from '@router/Routes.config.definition';

type PrimitiveType = string | number | boolean | null | undefined | Date;

const isMatchPath = (pathname: string, path: string): boolean => Boolean(matchPath(path, pathname));

const platform = Platform.OS;
const linkProps = {action: true, upperCase: true, align: ALIGN.CENTER};

interface IsolatedPanelProps {
  readonly visible: boolean;
  readonly authorization: boolean;
  readonly isRafExpEnabled: boolean;
  readonly referralCredit: number;
  readonly userCanAccessShop: boolean;
  readonly filteredLinks: Link[];
  readonly onClose: () => void;
  readonly openLink: (link: string) => void;
  readonly formatMessage: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
}

const IsolatedPanel: FC<IsolatedPanelProps> = ({
  visible,
  authorization,
  isRafExpEnabled,
  referralCredit,
  userCanAccessShop,
  filteredLinks,
  onClose,
  openLink,
  formatMessage,
}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  const showAppDownloadButton = platform === 'web' && authorization;
  const mainWebLinks = Object.values<Route>(MAIN_MENU_ITEMS);

  const isStyleProfilePath = useMemo(
    () => isMatchPath(pathname, Path.STYLE_HOME) || isMatchPath(pathname, Path.STYLE_PROFILE),
    [pathname],
  );

  const handleNavigate = useCallback(
    (path: Path, options?: NavigateOptions) => {
      onClose();
      navigate(path, options);
    },
    [navigate, onClose],
  );

  return (
    <Panel align={ALIGN.RIGHT} color={COLOR.INFO} isVisible={visible} onClose={onClose}>
      {iOSInAppBrowserVideoAd && <InAppBrowserBanner />}
      <ScrollView stickyHeaderIndices={[0]} contentContainerStyle={style.content}>
        <SafeAreaView style={style.content}>
          <Header color={COLOR.INFO} logo={<Logo />} onClose={onClose} />
          <View
            display={DISPLAY.FLEX}
            flex={SIZE.XS}
            paddingHorizontal={LAYOUT.S}
            paddingTop={LAYOUT.L}
            style={style.container}
          >
            <View>
              {authorization && (
                <>
                  {platform === 'web' &&
                    mainWebLinks.map((route, index) => {
                      const isShop = route.path.match(/^\/shop/);
                      const isRaf = isMatchPath(pathname, Path.REFERRALS);

                      return (
                        <React.Fragment key={index}>
                          {!isShop ? (
                            <View
                              alignItems={ALIGN.CENTER}
                              key={route.path}
                              marginBottom={index < mainWebLinks.length - 1 ? LAYOUT.XS : LAYOUT.XL}
                              position={POSITION.RELATIVE}
                            >
                              <AuroraLink
                                align={ALIGN.CENTER}
                                color={isMatchPath(pathname, route.path) ? COLOR.PRIMARY : undefined}
                                display={DISPLAY.BLOCK}
                                level={1}
                                style={style.mainLink}
                                onPress={() => handleNavigate(route.path)}
                                testID={route.testid}
                              >
                                {isRaf && isRafExpEnabled
                                  ? formatMessage(TEXT.RAF_EXP, {referralCredit: referralCredit || ''})
                                  : formatMessage({id: route.id})}
                              </AuroraLink>
                            </View>
                          ) : (
                            userCanAccessShop && (
                              <View
                                alignItems={ALIGN.CENTER}
                                display={DISPLAY.FLEX}
                                flexDirection={FLEX_DIRECTION.ROW}
                                justifyContent={ALIGN.CENTER}
                                key={route.path}
                                marginBottom={index < mainWebLinks.length - 1 ? LAYOUT.XS : LAYOUT.XL}
                                position={POSITION.RELATIVE}
                                testID={route.testid}
                              >
                                <AuroraLink
                                  align={ALIGN.CENTER}
                                  color={isMatchPath(pathname, route.path) ? COLOR.PRIMARY : undefined}
                                  display={DISPLAY.INLINE}
                                  level={1}
                                  style={style.mainLink}
                                  onPress={() => handleNavigate(route.path)}
                                  testID={route.testid}
                                >
                                  {formatMessage({id: route.id})}
                                </AuroraLink>
                              </View>
                            )
                          )}
                        </React.Fragment>
                      );
                    })}

                  {platform !== 'web' && (
                    <>
                      {userCanAccessShop ? (
                        SHOP_ROUTES.map(shopRoute => {
                          return (
                            <View
                              key={shopRoute}
                              alignItems={ALIGN.CENTER}
                              display={DISPLAY.FLEX}
                              flexDirection={FLEX_DIRECTION.ROW}
                              justifyContent={ALIGN.CENTER}
                              marginBottom={LAYOUT.XS}
                              position={POSITION.RELATIVE}
                            >
                              <AuroraLink
                                {...linkProps}
                                color={
                                  isMatchPath(pathname, MAIN_MENU_ITEMS[shopRoute].path) ? COLOR.PRIMARY : undefined
                                }
                                onPress={() => handleNavigate(MAIN_MENU_ITEMS[shopRoute].path)}
                              >
                                {formatMessage({id: MAIN_MENU_ITEMS[shopRoute].id})}
                              </AuroraLink>
                            </View>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      <AuroraLink
                        {...linkProps}
                        color={isStyleProfilePath ? COLOR.PRIMARY : undefined}
                        marginBottom={LAYOUT.XS}
                        onPress={() => handleNavigate(Path.STYLE_HOME)}
                      >
                        {formatMessage({id: MAIN_MENU_ITEMS.STYLE.id})}
                      </AuroraLink>
                    </>
                  )}

                  <AuroraLink
                    {...linkProps}
                    color={isMatchPath(pathname, Path.ACCOUNT) ? COLOR.PRIMARY : undefined}
                    marginBottom={LAYOUT.XS}
                    onPress={() => handleNavigate(Path.ACCOUNT)}
                    testID="link-account"
                  >
                    {formatMessage(TEXT.ACCOUNT_CTA)}
                  </AuroraLink>
                </>
              )}
              {filteredLinks.map((item, index) => (
                <AuroraLink
                  {...linkProps}
                  href={item.path}
                  key={item.path}
                  marginBottom={index < filteredLinks.length - 1 ? LAYOUT.XS : undefined}
                  testID={item.testid}
                  {...(platform !== 'web' && {
                    onPress: () => {
                      onClose();
                      openLink(item.path);
                    },
                  })}
                >
                  {formatMessage({id: item.id})}
                </AuroraLink>
              ))}
              {!authorization && (
                <>
                  <Box size={{M: '2/3', L: '1/3'}} align={ALIGN.CENTER}>
                    <Button
                      style={{flex: 0}}
                      marginVertical={LAYOUT.M}
                      onPress={() => handleNavigate(Path.QUIZ_HOME, {state: {from: Path.LOGIN}})}
                    >
                      {formatMessage(TEXT.QUIZ_CTA)}
                    </Button>
                  </Box>
                  <Text align={ALIGN.CENTER} marginBottom={LAYOUT.XXS}>
                    {formatMessage(TEXT.LOGIN)}
                  </Text>
                  <Button
                    variant={BUTTON_VARIANT.SECONDARY}
                    onPress={() => handleNavigate(Path.LOGIN)}
                    small
                    wide={false}
                  >
                    {formatMessage(TEXT.LOGIN_CTA)}
                  </Button>
                </>
              )}
            </View>
            <View paddingTop={LAYOUT.XL} paddingBottom={LAYOUT.M}>
              {showAppDownloadButton && <DownloadButton source={SOURCE.SIDE_MENU} />}
              {platform !== 'web' && <AppVersion />}
            </View>
          </View>
        </SafeAreaView>
      </ScrollView>
    </Panel>
  );
};

export {IsolatedPanel};
