import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '$colorBase',
    height: 48, // avoid using token because of tiny theme
    justifyContent: 'space-between',
  },
  menu: {
    justifyContent: 'center',
    marginRight: '$spaceS',
    marginLeft: 'auto',
  },
});
