import React, {useCallback} from 'react';
import {GestureResponderEvent} from 'react-native';
import {useIntl} from 'react-intl';
import {matchPath, useLocation, useNavigate} from 'react-router-native';
import {DISPLAY, FLEX_DIRECTION, ALIGN, View, useDevice} from '@lookiero/aurora';
import {useShop} from '@components/_context';
import {useRafSectionNaming} from '@mendel/_experiments/useRafSectionNaming';
import {Path} from '@router/Routes.config.definition';

import {NavButton} from '../NavButton';
import {TEXT} from './NavOptions.definition';
import {Skeleton} from './Skeleton';
import {styles} from './NavOptions.style';

export const NavOptions: React.FC = () => {
  const {canAccess, contextLoading} = useShop();
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const {isLoading: isRafExpLoading, enabled: isRafExpEnabled, credit: referralCredit} = useRafSectionNaming({
    isNewNavigation: true,
  });

  const handleRedirect = useCallback((path: string) => navigate(path), [navigate]);

  const panelStyle = screen.L ? styles.panelLarge : styles.panel;
  const buttonStyle = screen.L ? styles.navBarButtonLarge : undefined;

  const areActive = (...paths: Path[]): boolean => paths.some(path => matchPath(path, pathname));

  const onPress = (path: Path) => (event: GestureResponderEvent) => {
    event.preventDefault();
    handleRedirect(path);
  };

  const isLoading = contextLoading || isRafExpLoading;

  if (isLoading) return <Skeleton />;

  return (
    <View
      display={DISPLAY.FLEX}
      flexDirection={FLEX_DIRECTION.ROW}
      justifyContent={ALIGN.AROUND}
      alignItems={ALIGN.CENTER}
      wide={!screen.L}
      style={panelStyle}
    >
      <NavButton
        onPress={onPress(Path.HOME)}
        active={areActive(Path.HOME)}
        icon="mybox"
        text={formatMessage(TEXT.BOX)}
        style={buttonStyle}
        testID="navbar-home"
      />
      {canAccess && (
        <NavButton
          onPress={onPress(Path.SHOP)}
          active={areActive(Path.SHOP)}
          icon="tag"
          text={formatMessage(TEXT.SHOP)}
          style={buttonStyle}
          testID="navbar-shop"
        />
      )}
      <NavButton
        onPress={onPress(Path.REFERRALS)}
        active={areActive(Path.REFERRALS)}
        icon={isRafExpEnabled ? 'gift' : 'faces'}
        text={isRafExpEnabled ? formatMessage(TEXT.RAF_EXP, {referralCredit}) : formatMessage(TEXT.FRIENDS)}
        style={buttonStyle}
        testID="navbar-raf"
      />
      <NavButton
        onPress={onPress(Path.PROFILE_MENU)}
        active={areActive(Path.ACCOUNT, Path.PROFILE_MENU)}
        icon="profile"
        text={formatMessage(TEXT.PROFILE)}
        style={buttonStyle}
        testID="navbar-profile"
      />
    </View>
  );
};
