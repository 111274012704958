import React from 'react';
import {View, ButtonIcon} from '@lookiero/aurora';

import {NavLogo} from '@components/atoms/NavLogo';
import {style} from './BurgerBar.style';

export type BurgerBarProps = {
  busy?: boolean;
  hideMenu?: boolean;
  hideLogo?: boolean;
  notification?: boolean;
  onPressMenu?: () => void;
  banner?: React.ReactNode;
};

export const BurgerBar: React.FC<BurgerBarProps> = ({
  busy = false,
  hideMenu = false,
  hideLogo = false,
  notification = false,
  onPressMenu = () => undefined,
  banner,
}) => {
  return (
    <>
      <View style={style.container}>
        {!hideLogo && <NavLogo busy={busy} />}
        {!hideMenu && (
          <View style={style.menu}>
            <ButtonIcon
              nativeID="menu-button"
              testID="menu-button"
              name="menu"
              notification={notification}
              onPress={onPressMenu}
            />
          </View>
        )}
      </View>
      {banner}
    </>
  );
};
