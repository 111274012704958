import {Shipment} from '@domain/model/Shipment/Shipment';
import {ShipmentRepository} from '@domain/model/Shipment/ShipmentRepository';

const GetShipmentByOrderReference = (shipmentRepository: ShipmentRepository) => (
  orderReference: string,
): Promise<Shipment> => {
  return shipmentRepository.getShipmentByOrderReference(orderReference);
};

export {GetShipmentByOrderReference};
