import {ApiSiteRepository} from '@infra/domain/model/Site/ApiSiteRepository';
import {LocationRepositoryImpl} from '@infra/domain/model/Site/LocationRepository';

import {GetMessagesByLocale} from './GetMessagesByLocale';
import {GetSettingsByCountry} from './GetSettingsByCountry';
import {LocationService} from './LocationService';

const SiteApplication = {
  getMessagesByLocale: GetMessagesByLocale(ApiSiteRepository),
  getSettingsByCountry: GetSettingsByCountry(ApiSiteRepository),
};

const {getStore, getLocale, getHostname, setStore, getSiteByCountry} = LocationService(LocationRepositoryImpl);
const LocationApplication = {
  getStore,
  getLocale,
  getHostname,
  setStore,
  getSiteByCountry,
};

export {SiteApplication, LocationApplication};
