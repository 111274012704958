import StyleSheet from 'react-native-extended-stylesheet';

export const style = StyleSheet.create({
  loading: {
    height: 54,
    left: 0,
    top: 0,
    width: 217,
  },
});
