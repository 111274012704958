import StyleSheet from 'react-native-extended-stylesheet';

const style = StyleSheet.create({
  notification: {
    borderRadius: '$spaceS / 2',
    width: '$spaceS',
    height: '$spaceS',
    top: '$spaceXXS',
    right: '$spaceM * -1',
  },
  container: {
    justifyContent: 'space-between',
    height: '100%',
  },
  mainLink: {
    lineHeight: null,
  },
  content: {
    height: '100%',
  },
});

export {style};
