import {useEffect, useState} from 'react';
import {Platform} from 'react-native';
import {sendException} from 'errors/errorLogger';

import {mendelService} from './mendel';

export type ExperimentVariant = string | undefined;
export type VariantsResult = Record<string, ExperimentVariant>;
export type ExperimentDefinition = {name: string; forcedVariant?: string; enabled?: boolean};
export type UseMultiExperimentsResult = {isLoading: boolean; variants: VariantsResult};
export type UseMultiExperimentsType = (args: {
  experiments: Array<ExperimentDefinition>;
  resolveOne?: boolean;
  isWaiting?: boolean;
}) => UseMultiExperimentsResult;

const isAssignable = (): boolean => Platform.OS === 'web';

const initVariants = (experiments: ExperimentDefinition[]): VariantsResult =>
  experiments.reduce((prev, curr) => ({...prev, [curr.name]: undefined}), {});

const resolveExperiment = async ({enabled, name, forcedVariant}: ExperimentDefinition): Promise<ExperimentVariant> => {
  if (enabled) {
    return mendelService.getVariantByExperimentName({name, forcedVariant}).catch(sendException);
  }
  return undefined;
};

const resolveExperimentList = async (
  experiments: Array<ExperimentDefinition>,
  resolveOne: boolean,
): Promise<VariantsResult> => {
  if (!resolveOne) {
    const result = await Promise.all(experiments.map(resolveExperiment));
    return experiments.reduce((prev, curr, index) => ({...prev, [curr.name]: result[index]}), {});
  }

  const result: VariantsResult = initVariants(experiments);
  for (let i = 0; i < experiments.length; i++) {
    result[experiments[i].name] = await resolveExperiment(experiments[i]);
    if (result[experiments[i].name]) break;
  }

  return result;
};

/**
 * Resolves the experiments provided in order of preference
 * @param experiments array of experiments to evaluate
 * @param resolveOne resolves the 1st assignable experiment and discards the rest
 * @param isWaiting true if we want to wait before evaluating the enabled property of every experiment
 */
const useMultiExperiments: UseMultiExperimentsType = ({experiments, resolveOne = false, isWaiting = false}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [variants, setVariants] = useState<VariantsResult>(initVariants(experiments));

  useEffect(() => {
    setVariants(initVariants(experiments));
    const enabled = experiments.some(exp => exp.enabled);
    if (!isAssignable() || (!enabled && !isWaiting)) {
      setIsLoading(false);
    } else if (isWaiting) {
      setIsLoading(true);
    } else {
      (async () => {
        setIsLoading(true);
        setVariants(await resolveExperimentList(experiments, resolveOne));
        setIsLoading(false);
      })();
    }
  }, [experiments, isWaiting, resolveOne]);

  return {isLoading, variants};
};

export {useMultiExperiments};
