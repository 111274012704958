import {ALIGN, LAYOUT, Box, Layout, Text} from '@lookiero/aurora';
import React, {useLayoutEffect, useMemo, useCallback} from 'react';
import {useIntl} from 'react-intl';

import {useApp, useHeader} from '@app';

import {style} from './NoInternet.style';
import {TEXT} from './NoInternet.definition';

import {useNavigate} from 'react-router-native';
import {useHardwareBackPress} from '@router/useHardwareBackPress';

export const NoInternet = () => {
  const {setLoading} = useApp();
  const {formatMessage} = useIntl();

  useHeader(useMemo(() => ({showMenu: false}), []));

  const navigate = useNavigate();
  const onBack = useCallback(() => navigate(-1), [navigate]);
  useHardwareBackPress(onBack);

  useLayoutEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout justifyContent={ALIGN.CENTER} inline={false} style={style.container}>
      <Box alignItems={ALIGN.CENTER}>
        <Text align={ALIGN.CENTER} heading level={2} marginBottom={LAYOUT.XXS}>
          {formatMessage(TEXT.TITLE)}
        </Text>
        <Text>{formatMessage(TEXT.TEXT)}</Text>
      </Box>
    </Layout>
  );
};
