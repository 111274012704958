const FEATURE_FLAGS = {
  GERMANY: 'GERMANY',
  AUSTRIA: 'AUSTRIA',
  GOOGLE_SIGN_IN: 'GOOGLE_SIGN_IN',
  RAF_REFERRAL_SEPT22: 'RAF_REFERRAL_SEPT22',
  RAF_REFERRED_SEPT22: 'RAF_REFERRED_SEPT22',
  FIVE_GARMENTS_DISCOUNT_ZERO: 'FIVE_GARMENTS_DISCOUNT_ZERO',
  SURVEY_HDYMU: 'SURVEY_HDYMU',
  SALES_PILL: 'SALES_PILL',
  SALES_BANNER: 'SALES_BANNER',
  SALES_NOTIFICATION: 'SALES_NOTIFICATION',
  SALES_CATEGORIES_BANNER: 'SALES_CATEGORIES_BANNER',
};

export {FEATURE_FLAGS};
