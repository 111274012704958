import gql from 'graphql-tag';

const GET_REFERRAL_FOR_SHARE_QUERY = gql`
  query rafInfo {
    rafInfo {
      referralCode
      referralCredit
      referredCredit
      enabled
    }
  }
`;

export {GET_REFERRAL_FOR_SHARE_QUERY};
