import PropTypes from 'prop-types';
import React from 'react';

import {InputField} from '@lookiero/aurora';

const normalizeValue = value => (value !== undefined && value !== null ? value.toString() : value);

export const Dropdown = ({emptyOption, onChange, options, value, ...others}) => {
  const normalizedOptions = options.map(option => ({
    ...option,
    value: normalizeValue(option.value),
  }));
  const normalizedValue = normalizeValue(value);

  return (
    <InputField
      {...others}
      value={normalizedValue}
      emptyOptionText={emptyOption}
      icon="arrow-down"
      onChange={onChange}
      options={normalizedOptions}
    />
  );
};

Dropdown.propTypes = {
  emptyOption: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.string,
};
