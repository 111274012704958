import PropTypes from 'prop-types';
import React from 'react';
import {Image} from 'react-native';

import {Dark, Light} from './Logo.Base64';

const Logo = ({light = false}) => (
  <Image source={{uri: light ? Light : Dark}} resizeMode="cover" style={{height: 54, width: 217}} />
);

Logo.propTypes = {
  light: PropTypes.bool,
};

export {Logo};
