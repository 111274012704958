import {experiments} from 'ui/config';
import {useExperiment} from '@mendel/useExperiment';
import {ReferralApplication} from '@raf/core/application';
import {ReferralForShare} from '@raf/core/domain/ReferralForShare';
import {useEffect, useState} from 'react';
import {useApp} from '@app';

export type UseRafSectionNamingType = (params?: {
  isNewNavigation?: boolean;
}) => {
  isLoading: boolean;
  enabled: boolean;
  credit: number;
};

export const useRafSectionNaming: UseRafSectionNamingType = ({isNewNavigation} = {}) => {
  const [referral, setReferral] = useState<ReferralForShare>();
  const [isLoading, setIsLoading] = useState(false);
  const [enableExperiment, setEnableExperiment] = useState(false);
  const {authorization, isPanelVisible} = useApp();

  const {name: expName, variants: expVariants} = experiments.RAF_SECTION_NAMING;
  const experiment = useExperiment({name: expName, enabled: enableExperiment});
  const isExperimentActive = experiment.variant === expVariants.v1;

  useEffect(() => {
    const isApplicable = isNewNavigation || (!isNewNavigation && Boolean(isPanelVisible));
    const shouldGetExperiment = Boolean(authorization) && isApplicable;
    setEnableExperiment(current => current || shouldGetExperiment);
  }, [authorization, isNewNavigation, isPanelVisible]);

  useEffect(() => {
    if (isExperimentActive) {
      setIsLoading(true);
      ReferralApplication.getReferralForShare()
        .then(setReferral)
        .finally(() => setIsLoading(false));
    }
  }, [isExperimentActive]);

  return {
    enabled: isExperimentActive,
    isLoading: experiment.isLoading || isLoading,
    credit: (referral && referral.referralCredit) || 0,
  };
};
