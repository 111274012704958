import {AuthRepository} from '@domain/model/Auth/AuthRepository';
import {RegisterUserCommandGoogle} from '@domain/model/Auth/RegisterUserCommand';

const RegisterUserGoogle = async (
  authRepository: AuthRepository,
  createUserCommand: RegisterUserCommandGoogle,
): Promise<string | null> => {
  try {
    const {tokenId, googleCode, platform} = createUserCommand;
    if (platform && ((platform.native && !tokenId) || (platform.web && !googleCode))) {
      throw new Error('Google token missing');
    }
    delete createUserCommand['platform'];
    const token = await authRepository.registerUserGoogle(createUserCommand);
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject(e);
  }
};

export {RegisterUserGoogle};
