import {lazy} from 'react';

const toDefault = key => ({[key]: component}) => ({default: component});

export const Account = lazy(() => import('@views/Account').then(toDefault('Account')));
export const Login = lazy(() => import('@views/Login').then(toDefault('Login')));
export const MyLookiero = lazy(() => import('@views/MyLookiero').then(toDefault('MyLookiero')));
export const ResetPassword = lazy(() => import('@views/ResetPassword').then(toDefault('ResetPassword')));
export const Landing = lazy(() => import('@views/Referrals/ReferralsLanding').then(toDefault('ReferralsLanding')));
export const Profile = lazy(() => import('@views/Style/Profile').then(toDefault('Profile')));
export const Referrals = lazy(() => import('@views/Referrals/Referrals').then(toDefault('Referrals')));
export const Shop = lazy(() => import('@views/Shop').then(toDefault('Shop')));
export const PublicReturnOrder = lazy(() => import('@views/PublicReturnOrder').then(toDefault('PublicReturnOrder')));
export const ProfileMenu = lazy(() => import('@views/ProfileMenu/ProfileMenu').then(toDefault('ProfileMenu')));
export const Checkout = lazy(() => import('@modules/checkout/ui/views/checkout/Checkout').then(toDefault('Checkout')));
export const Quiz = lazy(() => import('@modules/quiz/ui/views/quiz/Quiz').then(toDefault('Quiz')));
export const QuizRegister = lazy(() =>
  import('@views/Style/QuizRegister/QuizRegister.view').then(toDefault('QuizRegisterView')),
);
export const StyleProfile = lazy(() =>
  import('@modules/styleProfile/ui/views/styleProfile/StyleProfile').then(toDefault('StyleProfile')),
);
export const MyPlan = lazy(() => import('@views/Account/_experiments/osr/Plan/MyPlan').then(toDefault('MyPlan')));
