import {useCallback, useEffect} from 'react';
import {BackHandler, Platform} from 'react-native';

interface HandleBackFunction {
  (): boolean | undefined | void;
}

interface UseHardwareBackPressFunction {
  (handleBack: HandleBackFunction): void;
}

const useHardwareBackPress: UseHardwareBackPressFunction = handleBack => {
  const handleHardwareBackPress = useCallback(() => {
    const disableOtherListeners = handleBack();

    return typeof disableOtherListeners === 'boolean' ? disableOtherListeners : true;
  }, [handleBack]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      return;
    }

    const subscription = BackHandler.addEventListener('hardwareBackPress', handleHardwareBackPress);

    return subscription.remove;
  }, [handleHardwareBackPress]);
};

export {useHardwareBackPress};
