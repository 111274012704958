import {useEffect, useState} from 'react';
import Braze from '@braze/react-native-sdk';

export const useHasUnreadInboxContentCards = routeName => {
  const [inboxUnread, setInboxUnread] = useState(false);

  useEffect(() => {
    Braze.requestContentCardsRefresh();
  }, [routeName]);

  useEffect(() => {
    const contentCardsSubscription = Braze.addListener(Braze.Events.CONTENT_CARDS_UPDATED, ({cards}) => {
      setInboxUnread(prevInboxUnread =>
        hasUnreadInboxContentCards(cards) === prevInboxUnread ? prevInboxUnread : !prevInboxUnread,
      );
    });

    return () => contentCardsSubscription.remove();
  }, []);

  return inboxUnread;
};

const hasUnreadInboxContentCards = cards =>
  Object.values(cards).some(card => card.extras.feed_type === 'inbox' && !card.viewed);
