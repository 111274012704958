import {AuthLogin} from '@domain/model/Auth/AuthLogin';
import {AuthLoginGoogle} from '@domain/model/Auth/AuthLoginGoogle';
import {RegisterUserCommand, RegisterUserCommandGoogle} from '@domain/model/Auth/RegisterUserCommand';
import {Auth} from '@domain/model/Auth/Auth';
import {apiAuth} from '@infra/api/rest/domain/model/Auth/apiAuth';

import {getAuth, removeAuth, setAuth} from '@infra/api/authManager/authManager';

import {executeMutation} from '@infra/api/graphQL/client';
import {Mutations} from '@infra/api/graphQL/domain/model/Auth/mutations';
import {UserLoginInfo} from '@domain/model/Auth/UserLoginInfo';
import {decodePayload} from '@infra/domain/model/_utils/jwt';

const FIRST_LOGIN_KEY = 'FIRST_LOGIN';

const login = async (login: AuthLogin): Promise<string> => {
  const {
    retrieveToken: {token},
  } = await executeMutation<Record<string, Auth>>(Mutations.RETRIEVE_TOKEN, {...login});
  if (token) {
    await setAuth(token);
  }
  return token;
};

const isLoggedIn = async (): Promise<boolean> => !!(await getAuth());

const logOut = async (): Promise<void> => await removeAuth();

const loginWithGoogle = async (tokenId: AuthLoginGoogle): Promise<string> => {
  try {
    const token = await apiAuth.loginWithGoogle(tokenId);
    if (token) {
      await setAuth(token);
    }
    return token;
  } catch (error) {
    return Promise.reject(error);
  }
};

const recoverPassword = async ({email}: {email: string}): Promise<Record<string, string>> => {
  try {
    return await executeMutation(Mutations.FORGOT_PASSWORD, {email});
  } catch (e) {
    return Promise.reject(e);
  }
};

const registerUser = async (registerUserCommand: RegisterUserCommand): Promise<string> => {
  try {
    const {token} = await apiAuth.registerUser(registerUserCommand);
    if (token) await setAuth(token);
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject(e);
  }
};
const registerUserGoogle = async (registerUserCommandGoogle: RegisterUserCommandGoogle): Promise<string> => {
  try {
    const {token} = await apiAuth.registerUserGoogle(registerUserCommandGoogle);
    if (token) await setAuth(token);
    return Promise.resolve(token);
  } catch (e) {
    return Promise.reject(e);
  }
};

const urlBase64Decode = (str = ''): string => {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      output += '==';
      break;
    }
    case 3: {
      output += '=';
      break;
    }
    default: {
      throw 'Illegal base64url string!';
    }
  }
  return decodePayload(output);
};

const getLoginInfo = async (): Promise<UserLoginInfo> => {
  try {
    const auth = await getAuth();
    if (auth) {
      const parts = auth.split('.') || [];
      const decoded = urlBase64Decode(parts[1]) || '{}';
      const {sub, country_code: countryCode, displayName, accessVia} = JSON.parse(decoded);
      return Promise.resolve({
        id: sub,
        country: countryCode,
        username: displayName,
        loginMethod: accessVia,
      });
    }
    return Promise.reject('not-logged-in');
  } catch (e) {
    return Promise.reject(e);
  }
};

export const ApiAuthRepository = {
  isLoggedIn,
  logOut,
  login,
  recoverPassword,
  registerUser,
  registerUserGoogle,
  loginWithGoogle,
  getLoginInfo,
};

export {FIRST_LOGIN_KEY};
