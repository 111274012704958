import React from 'react';
import {POSITION, View, useDevice} from '@lookiero/aurora';
import {NavLogo} from '@components/atoms/NavLogo';

import {NavOptions} from './NavOptions';
import {style, menuMobileHeight, menuDesktopHeight} from './NavigationBar.style';

export type NavigationBarProps = {
  busy?: boolean;
  hideMenu?: boolean;
  banner?: React.ReactNode;
};

// height header difference from desktop to mobile
const HEIGHT_HEADER_DIFFERENCE = menuMobileHeight - menuDesktopHeight;

export const NavigationBar: React.FC<NavigationBarProps> = ({busy = false, hideMenu = false, banner}) => {
  const {screen} = useDevice();

  const bannerMarginTop = screen.S || screen.M ? HEIGHT_HEADER_DIFFERENCE : 0;

  return (
    <View style={[style.navBarContainer, screen.L && style.navBarLarge]}>
      {screen.L && <NavLogo busy={busy} />}
      {!hideMenu && <NavOptions />}
      {banner && (
        <View position={POSITION.ABSOLUTE} style={{marginTop: bannerMarginTop}}>
          {banner}
        </View>
      )}
    </View>
  );
};
