import {SiteDTO} from '@domain/model/Site/Site';
import {SiteRepository} from '@domain/model/Site/SiteRepository';

export const GetSettingsByCountry = (
  siteRepository: SiteRepository,
): ((country: string) => Promise<SiteDTO | null>) => (country: string): Promise<SiteDTO | null> => {
  if (!country) {
    return Promise.resolve(null);
  }

  return siteRepository.getSettingsByCountry(country);
};
