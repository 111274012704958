export const ThemeTiny = {
  /* FONT */
  $fontHeadingSize1: 84,
  $fontHeadingHeight1: 75,

  $fontHeadingSize2: 60,
  $fontHeadingHeight2: 54,

  $fontHeadingSize3: 40,
  $fontHeadingHeight3: 40,

  $fontBodySize1: 30,
  $fontBodyHeight1: 32,

  $fontBodySize2: 19,
  $fontBodyHeight2: 21,

  $fontBodySize3: 16,
  $fontBodyHeight3: 18,

  $fontActionSize1: 36,
  $fontActionHeight1: 36,

  $fontActionSize2: 18,
  $fontActionHeight2: 20,

  $fontActionSize3: 11,
  $fontActionHeight3: 12,

  $fontDetailSize1: 14,
  $fontDetailHeight1: 16,

  $fontDetailSize2: 11,
  $fontDetailHeight2: 14,

  $fontDetailSize3: 9,
  $fontDetailHeight3: 14,

  /* LAYOUT */
  $layoutXXS: 8,
  $layoutXS: 16,
  $layoutS: 24,
  $layoutM: 32,
  $layoutL: 48,
  $layoutXL: 64,
  $layoutXXL: 80,
  $layoutXXXL: 96,
};
