import React, {FC} from 'react';
import {
  FLEX_DIRECTION,
  LAYOUT,
  //Components
  Text,
  View,
  Button,
} from '@lookiero/aurora';

import {style} from '../ModalReturnInstructions.styles';
import {TEXT} from '../ModalReturnInstructions.definition';
import {useIntl} from 'react-intl';
import {openExternalLink} from '@router/helpers';

interface ModalReturnInstructionsProps {
  links: {[k: string]: string | undefined};
  orderReference: string;
}

export const ModalReturnInstructionsEVRI: FC<ModalReturnInstructionsProps> = ({links, orderReference}) => {
  const {formatMessage} = useIntl();
  return (
    <>
      <View marginBottom={LAYOUT.S} style={style.steps}>
        <View flexDirection={FLEX_DIRECTION.ROW} key={0} marginBottom={LAYOUT.XS}>
          <Text style={[style.step, style.index]}>1.</Text>
          <Text style={style.step}>
            {formatMessage(orderReference !== undefined ? TEXT.STEPS.ONE : TEXT.SHOP_STEPS.ONE)}
          </Text>
        </View>
        <View flexDirection={FLEX_DIRECTION.ROW} key={1} marginBottom={LAYOUT.XS}>
          <Text style={[style.step, style.index]}>2.</Text>
          <Text style={style.step}>
            {formatMessage(orderReference !== undefined ? TEXT.STEPS.TWO : TEXT.SHOP_STEPS.TWO)}
          </Text>
        </View>
        {orderReference !== undefined && (
          <View flexDirection={FLEX_DIRECTION.ROW} key={2}>
            <Text style={[style.step, style.index]}>3.</Text>
            <Text style={style.step}>{formatMessage(TEXT.STEPS.THREE)}</Text>
          </View>
        )}
      </View>
      <Button
        onPress={() => openExternalLink(links.searchPickupPoint)}
        style={style.buttonCouriers}
        testID={`find-drop-off-button`}
      >
        {formatMessage(TEXT.RETURN_BUTTON_GB)}
      </Button>
    </>
  );
};
