const inAppBrowser =
  /FB_IAB/.test(navigator.userAgent) ||
  /FBAN/.test(navigator.userAgent) ||
  /FBAV/.test(navigator.userAgent) ||
  /Pinterest/.test(navigator.userAgent);

const iOSInAppBrowser = window.screen.height > 667 && /FBIOS/.test(navigator.userAgent);

const isAndroidInAppBrowser = inAppBrowser && !iOSInAppBrowser;

/*
 Check if FB in-app browser interface height is lower than 250px and greater than 100px.
 Then the in-app browser was triggered by the FB CTA below the video.
*/
const iOSInAppBrowserVideoAd =
  iOSInAppBrowser &&
  !(window.screen.availHeight - window.innerHeight < 250 && window.screen.availHeight - window.innerHeight > 100);

const iOSInAppBrowserBannerHeight = 66;

const iOSInAppBrowserBannerTop = iOSInAppBrowserVideoAd ? iOSInAppBrowserBannerHeight : 0;

export {
  inAppBrowser,
  iOSInAppBrowser,
  isAndroidInAppBrowser,
  iOSInAppBrowserVideoAd,
  iOSInAppBrowserBannerHeight,
  iOSInAppBrowserBannerTop,
};
