export const openExternalLink = async (link, createLink) => {
  if (createLink) {
    const linkEl = document.createElement('a');
    linkEl.setAttribute('href', link);
    linkEl.setAttribute('target', '_blank');
    linkEl.click();
    linkEl.remove();
  } else {
    window.open(link, '_blank');
  }
};
