import React, {useState, useEffect, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {
  // Components
  Link,
  Text,
  View,
  // Helpers
  SIZE,
} from '@lookiero/aurora';

import {useToast, generateUid} from '@components/_context';
import {ModalReturnInstructions} from '@components/atoms';
import {OrderService} from '@domain/model/Order';
import {TEXT} from './NotificationReturnInstructions.definition';

interface NotificationReturnInstructionsProps {
  readonly orderPendingReturn: OrderService | undefined;
}

const NotificationReturnInstructions: React.FC<NotificationReturnInstructionsProps> = ({orderPendingReturn}) => {
  const {formatMessage} = useIntl();
  const uid = generateUid();
  const toast = useToast(uid);
  const [modalInstructions, setModalInstructions] = useState<boolean>(false);

  const onOpenInstructionsModal = useCallback(() => setModalInstructions(true), []);
  const onCloseInstructionsModal = useCallback(() => setModalInstructions(false), []);

  useEffect(() => {
    if (orderPendingReturn) {
      toast.showSuccess({
        message: (
          <>
            <View wide>
              <Text detail level={2}>
                {formatMessage(TEXT.ACTION_RETURN_TITLE)}
              </Text>
            </View>
            <Link detail level={2} onPress={onOpenInstructionsModal} underlined>
              {formatMessage(TEXT.ACTION_RETURN_LINK)}
            </Link>
          </>
        ),
        settings: {
          timeoutClose: 0,
        },
      });
      return () => {
        toast.hide(uid);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {orderPendingReturn && modalInstructions ? (
        <ModalReturnInstructions
          orderReference={`LK-${orderPendingReturn.id}`}
          serviceId={undefined}
          layer={SIZE.XL}
          onClose={onCloseInstructionsModal}
          isVisible={true}
        />
      ) : null}
    </>
  );
};

export {NotificationReturnInstructions};
