import {setPromocodesBridge} from '@lookiero/promocodes-front';
import {getAuth} from '@infra/api/authManager/authManager';
import {useAvoidKeyboard} from '@router/bridges/KeyboardAvoidingView';
import {emitUserEvent} from '@lookiero/user-tracking-front';
import {useFeatureFlags} from '@components/_context';
import {proxy} from '@infra/api/fetch/proxy';

import {version as nativeVersion} from '../../../../native/package.json';

export const configurePromoCodes = (): void => {
  setPromocodesBridge({
    getToken: getAuth,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useAvoidKeyboard,
    emitTrackingEvent: emitUserEvent,
    appVersion: nativeVersion,
    useFeatureFlags,
    proxy: proxy,
  });
};
