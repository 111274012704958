import {AppleRepository} from '@domain/model/Apple/AppleRepository';
import {CheckEmailRepository} from '@domain/model/Account/CheckEmail/CheckEmailRepository';
import {EmailNotExistError} from '@domain/model/Account/CheckEmail/EmailNotExistError';

const HandleAppleAuthentication = (
  appleRepository: AppleRepository,
  checkEmailRepository: CheckEmailRepository,
): (() => Promise<{email: string}>) => async (): Promise<{email: string}> => {
  const email = await appleRepository.getEmail();
  try {
    await checkEmailRepository.checkEmail(email);
  } catch (error) {
    if (error.code === 401) {
      throw new EmailNotExistError();
    }

    throw error;
  }

  return {email};
};

export {HandleAppleAuthentication};
