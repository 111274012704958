import {Path} from '@router/Routes.config.definition';
import {useMatch} from 'react-router-native';

interface UseIsUnstableNestedScrollView {
  readonly UNSTABLE_NESTED_SCROLL_IN_VIEW: boolean;
}

interface UseUnstableNestedScrollInViewFunction {
  (): UseIsUnstableNestedScrollView;
}

// Strong coupling to /shop routes to make DBF nested scroll work properly
const useUnstableNestedScrollInView: UseUnstableNestedScrollInViewFunction = () => {
  const isShopRoute = Boolean(useMatch(Path.SHOP));

  return {
    UNSTABLE_NESTED_SCROLL_IN_VIEW: isShopRoute,
  };
};

export {useUnstableNestedScrollInView};
